// src/components/Students/Homepage/Bee.js

import React, { useState, useEffect } from 'react';
import beesolo from '../../assets/beesolo.svg'; // Ensure the correct path
import './Bee copy.css'; // Import Bee-specific styles

const Bee = ({ id, boundaries, name }) => {
  // Constants
  const beeSize = 22; // Width of the bee image

  // Ensure boundaries are available
  const containerWidth = boundaries?.width || 0;
  const containerHeight = boundaries?.height || 0;

  // State for position
  const [x, setX] = useState(containerWidth / 2 - beeSize / 2);
  const [y, setY] = useState(containerHeight / 2 - beeSize / 2);

  // State for current velocity
  const [dx, setDx] = useState((Math.random() - 0.5) * 0.5); // Slower initial velocity
  const [dy, setDy] = useState((Math.random() - 0.5) * 0.5);

  // State for target velocity
  const [targetDx, setTargetDx] = useState((Math.random() - 0.5) * 0.5);
  const [targetDy, setTargetDy] = useState((Math.random() - 0.5) * 0.5);

  // State for flipping the bee image
  const [flipped, setFlipped] = useState(dx < 0);

  // Update position and velocity at regular intervals
  useEffect(() => {
    // Only start movement if boundaries are set
    if (containerWidth === 0 || containerHeight === 0) return;

    const interval = setInterval(() => {
      // Gradually adjust dx and dy towards targetDx and targetDy
      setDx((prevDx) => {
        const newDx = prevDx + (targetDx - prevDx) * 0.05;
        return Math.abs(newDx) < 0.01 ? targetDx : newDx;
      });
      setDy((prevDy) => {
        const newDy = prevDy + (targetDy - prevDy) * 0.05;
        return Math.abs(newDy) < 0.01 ? targetDy : newDy;
      });

      // Update position based on current velocity
      setX((prevX) => {
        let newX = prevX + dx * 2;
        if (newX < 0) {
          newX = 0;
          setDx(-dx); // Reverse direction on X axis
        } else if (newX > containerWidth - beeSize) {
          newX = containerWidth - beeSize;
          setDx(-dx);
        }
        return newX;
      });

      setY((prevY) => {
        let newY = prevY + dy * 2;
        if (newY < 0) {
          newY = 0;
          setDy(-dy); // Reverse direction on Y axis
        } else if (newY > containerHeight - beeSize) {
          newY = containerHeight - beeSize;
          setDy(-dy);
        }
        return newY;
      });
    }, 30); // Update every 30ms for smoother movement

    return () => clearInterval(interval);
  }, [dx, dy, targetDx, targetDy, containerWidth, containerHeight]);

  // Randomly change target velocities at intervals
  useEffect(() => {
    const changeTargetVelocity = () => {
      setTargetDx((Math.random() - 0.5) * 0.5);
      setTargetDy((Math.random() - 0.5) * 0.5);
    };

    const interval = setInterval(() => {
      changeTargetVelocity();
    }, Math.random() * 3000 + 2000); // Change every 2 to 5 seconds randomly

    return () => clearInterval(interval);
  }, []);

  // Update the flipped state whenever dx changes
  useEffect(() => {
    setFlipped(dx < 0);
  }, [dx]);

  // Update position when boundaries change from 0 to actual dimensions
  useEffect(() => {
    if (containerWidth > 0 && containerHeight > 0) {
      setX(Math.random() * (containerWidth - beeSize));
      setY(Math.random() * (containerHeight - beeSize));
    }
  }, [containerWidth, containerHeight, beeSize]);

  return (
    <div
      style={{
        position: 'absolute',
        left: x,
        top: y,
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <img
        src={beesolo}
        alt={`Flying Bee ${id + 1}`}
        className={`beesolo ${flipped ? 'flipped' : ''}`}
        style={{
          width: beeSize,
          height: 'auto',
          pointerEvents: 'none',
        }}
        aria-hidden="true"
      />
      <div
        style={{
          textAlign: 'center',
          marginTop: 4,
          fontSize: '12px',
          color: '#333',
          textShadow: '1px 1px 2px rgba(255, 255, 255, 0.7)',
        }}
      >
        {name}
      </div>
    </div>
  );
};

export default Bee;
