// src/components/LoginPage.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInAnonymously, signOut } from 'firebase/auth';
import { auth, rtdb } from '../../firebase'; // Ensure 'rtdb' is correctly initialized
import {
  ref,
  get,
  query as rtdbQuery,
  orderByValue,
  equalTo,
  update,
  set, // Import 'set' for writing mappings
} from 'firebase/database';
import quickthinkers2 from '../assets/QuickThinkers3.svg';
import './LoginPage.css';
import Bee from './HeaderFooterLayoutBees/Bee';

const LoginPage = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState('classCode');
  const [classCodeArray, setClassCodeArray] = useState(['', '', '', '', '', '']);
  const [loadingClass, setLoadingClass] = useState(false);
  const [classError, setClassError] = useState(null);
  const [classId, setClassId] = useState(null);
  const [classData, setClassData] = useState(null);
  const [students, setStudents] = useState([]);
  const [signedInStudents, setSignedInStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [connectLoading, setConnectLoading] = useState(false);
  const [connectError, setConnectError] = useState(null);

  // Refs for Intersection Observer
  const headerRef = useRef(null);
  const formRef = useRef(null);
  const [visibleSection, setVisibleSection] = useState([]);

  // Refs for input fields
  const inputRefs = useRef([]);

  // Intersection Observer for scroll-triggered animations
  useEffect(() => {
    const sections = [headerRef.current, formRef.current];
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisibleSection((prev) => [...prev, entry.target]);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        if (section) observer.unobserve(section);
      });
    };
  }, []);

  // Handle Input Change for Class Code
  const handleInputChange = (e, index) => {
    const value = e.target.value.toUpperCase();
    if (/^[A-Z0-9]$/.test(value) || value === '') {
      const newClassCodeArray = [...classCodeArray];
      newClassCodeArray[index] = value;
      setClassCodeArray(newClassCodeArray);

      if (value !== '' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  // Handle Key Down for Class Code Inputs
  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newClassCodeArray = [...classCodeArray];
      if (classCodeArray[index] === '') {
        if (index > 0) {
          newClassCodeArray[index - 1] = '';
          setClassCodeArray(newClassCodeArray);
          inputRefs.current[index - 1].focus();
        }
      } else {
        newClassCodeArray[index] = '';
        setClassCodeArray(newClassCodeArray);
      }
    }
  };

  // Handle Class Code Submission
  const handleClassCodeSubmit = async (e) => {
    if (e) e.preventDefault(); // Prevent default form submission

    if (classCodeArray.includes('')) {
      setClassError('Please enter the complete class code.');
      return;
    }

    setLoadingClass(true);
    setClassError(null);

    try {
      const normalizedClassCode = classCodeArray.join('').toUpperCase();

      // Query the 'classcodes' node in RTDB
      const classCodesRef = ref(rtdb, 'classcodes');
      const classCodeQuery = rtdbQuery(
        classCodesRef,
        orderByValue(),
        equalTo(normalizedClassCode)
      );
      const snapshot = await get(classCodeQuery);

      if (snapshot.exists()) {
        const classCodesData = snapshot.val();
        const fetchedClassId = Object.keys(classCodesData)[0];

        // Proceed to sign in anonymously
        let anonymousUser;

        if (auth.currentUser) {
          if (auth.currentUser.isAnonymous) {
            anonymousUser = auth.currentUser;
          } else {
            // Sign out the current user before signing in anonymously
            await signOut(auth);
            const userCredential = await signInAnonymously(auth);
            anonymousUser = userCredential.user;
          }
        } else {
          const userCredential = await signInAnonymously(auth);
          anonymousUser = userCredential.user;
        }

        // Now fetch the class data from 'activeclasses' in RTDB
        const activeClassRef = ref(rtdb, `activeclasses/${fetchedClassId}`);
        const activeClassSnapshot = await get(activeClassRef);
        if (activeClassSnapshot.exists()) {
          const fetchedClassData = activeClassSnapshot.val();

          // Extract students and signed-in students from the class data
          const beeNamesData = fetchedClassData.beeNames || {};
          const studentsList = Object.keys(beeNamesData);
          const currentSignedIn = studentsList.filter(
            (student) => beeNamesData[student].logged === 'yes'
          );
          setClassId(fetchedClassId);
          setClassData(fetchedClassData);
          setStudents(studentsList);
          setSignedInStudents(currentSignedIn);
          setStep('nameSelection');
        } else {
          setClassError('Active class data not found.');
        }
      } else {
        setClassError('Invalid class code.');
      }
    } catch (error) {
      setClassError(`An error occurred: ${error.message}`);
    } finally {
      setLoadingClass(false);
    }
  };

  // Handle Student Name Selection
  const handleStudentSelect = (student) => {
    if (signedInStudents.includes(student)) {
      setConnectError(
        'This name is already signed in. Please select another name.'
      );
      return;
    }
    setSelectedStudent(student);
    setConnectError(null);
  };

  // Handle Connect Button Click
  const handleConnect = async () => {
    if (!selectedStudent) {
      setConnectError('Please select a name.');
      return;
    }

    setConnectLoading(true);
    setConnectError(null);

    try {
      // Create a mapping of auth.uid to studentName and classID first
      const mappingRef = ref(rtdb, `studentMappings/${auth.currentUser.uid}`);
      await set(mappingRef, {
        classID: classId,
        studentName: selectedStudent,
      });

      // Update the student's 'logged' status to 'yes' in RTDB
      const studentRef = ref(
        rtdb,
        `activeclasses/${classId}/beeNames/${selectedStudent}`
      );
      await update(studentRef, { logged: 'yes' });

      // Now navigate to the student homepage
      navigate('/student');
    } catch (error) {
      setConnectError(error.message || 'Failed to connect. Please try again.');
    } finally {
      setConnectLoading(false);
    }
  };

  // Handle Back to Class Code Submission
  const handleBackToClassCode = async () => {
    setStep('classCode');
    setClassCodeArray(['', '', '', '', '', '']);
    setClassId(null);
    setClassData(null);
    setStudents([]);
    setSignedInStudents([]);
    setSelectedStudent(null);
    setConnectError(null);

    // Optionally, sign out the anonymous user if desired
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        await signOut(auth);
      } catch (error) {
        console.error('Error signing out:', error);
      }
    }
  };

  // Define the number of bees
  const numberOfBees = 30;

  return (
    <div className="relative flex flex-col min-h-screen overflow-hidden font-sans bg-transparent">
      {/* Header */}
      <header
        ref={headerRef}
        className={`bg-yellow-400 py-6 flex flex-col items-center sticky top-0 z-50 transition-transform duration-500 ${
          visibleSection.includes(headerRef.current)
            ? 'translate-y-0 opacity-100'
            : '-translate-y-10 opacity-0'
        }`}
      >
        <img
          src={quickthinkers2}
          alt="Quick Thinkers"
          className="mb-4 h-28"
        />
        <nav className="flex items-center">
          <Link
            to="/"
            className="mx-4 text-black transition-colors duration-300 hover:text-yellow-700"
          >
            Back
          </Link>
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex flex-col items-center justify-center flex-grow px-6 py-12 overflow-auto">
        <div
          ref={formRef}
          className={`w-full max-w-md bg-transparent p-8 transition-transform duration-500 ${
            visibleSection.includes(formRef.current)
              ? 'scale-100 opacity-100'
              : 'scale-95 opacity-0'
          } rounded-lg min-h-[400px] relative z-20`}
        >
          {/* Student Login Form */}
          {step === 'classCode' ? (
            <>
              <h2 className="mb-2 text-2xl font-semibold text-center text-black">
                Join a Class
              </h2>
              <p className="enter-code-text">Enter code</p>
              <form onSubmit={handleClassCodeSubmit}>
                <div className="hexagon-inputs">
                  {classCodeArray.map((value, index) => (
                    <div key={index} className="hexagon-container">
                      <div className="hexagon">
                        <input
                          type="text"
                          maxLength={1}
                          value={value}
                          onChange={(e) => handleInputChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                          className="absolute inset-0 w-full h-full text-center text-black uppercase bg-transparent border-none caret-yellow-800 focus:outline-none"
                          autoFocus={index === 0}
                          aria-label={`Class code character ${index + 1}`}
                        />
                        <span className="absolute inset-0 flex items-center justify-center text-lg font-bold pointer-events-none">
                          {value}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  type="submit"
                  className="submit-button"
                  disabled={loadingClass}
                >
                  {loadingClass ? 'Verifying...' : 'Submit'}
                </button>

                {/* Validation Messages Below the Submit Button */}
                {loadingClass && (
                  <p className="mt-4 text-center text-black">
                    Verifying class code...
                  </p>
                )}

                {classError && (
                  <p className="mt-6 text-center text-red-600">
                    {classError}
                  </p>
                )}
              </form>
            </>
          ) : (
            <>
              <h2 className="mb-6 text-2xl font-semibold text-center text-black">
                Select Your Name
              </h2>
              {connectError && (
                <p className="mb-4 text-center text-red-600">
                  {connectError}
                </p>
              )}
              <div className="mb-4 overflow-y-auto max-h-60">
                <ul className="space-y-2">
                  {students.map((student) => {
                    const isSignedIn = signedInStudents.includes(student);
                    const isSelected = selectedStudent === student;
                    return (
                      <li
                        key={student}
                        onClick={() => handleStudentSelect(student)}
                        className={`flex justify-between items-center px-4 py-3 border border-yellow-500 rounded-md cursor-pointer transition-transform duration-300 ${
                          isSignedIn
                            ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                            : isSelected
                            ? 'bg-yellow-500 scale-105 text-black'
                            : 'bg-yellow-200 text-black hover:bg-yellow-300'
                        }`}
                      >
                        <span>{student}</span>
                        {isSignedIn && (
                          <span className="text-sm">Signed In</span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex justify-between">
                <button
                  onClick={handleBackToClassCode}
                  className="px-4 py-2 font-bold text-black transition-colors duration-300 bg-yellow-400 border-4 rounded-full border-brown-800 hover:bg-yellow-500"
                >
                  Back
                </button>
                <button
                  onClick={handleConnect}
                  className={`px-4 py-2 font-bold text-black rounded-full border-4 border-brown-800 transition-colors duration-300 ${
                    !selectedStudent || connectLoading
                      ? 'bg-yellow-300 cursor-not-allowed'
                      : 'bg-yellow-400 hover:bg-yellow-500'
                  }`}
                  disabled={!selectedStudent || connectLoading}
                >
                  {connectLoading ? 'Connecting...' : 'Connect'}
                </button>
              </div>
            </>
          )}
        </div>
      </main>

      {/* Render Bees */}
      <div className="absolute inset-0 z-10 pointer-events-none">
        {Array.from({ length: numberOfBees }, (_, i) => (
          <Bee key={`bee-${i}`} id={i} />
        ))}
      </div>

      {/* Footer */}
      <footer className="py-6 text-center bg-transparent">
        <div className="flex flex-col items-center px-6 mx-auto max-w-7xl">
          <p className="text-sm text-gray-700">
            &copy; {new Date().getFullYear()} Bee Connect. All rights
            reserved.
          </p>
          <div className="flex mt-4 space-x-2">
            <a
              href="https://www.termsfeed.com/live/5f8cedc8-818c-4a5c-a29a-732f427711ef"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-700 transition-colors duration-300 hover:text-gray-900"
            >
              Privacy Policy
            </a>
            <span className="text-sm text-gray-700">|</span>
            <a
              href="https://www.termsfeed.com/live/4500990b-9e4b-49b6-894e-83a73efa479f"
              target="_blank"
              rel="noopener noreferrer"
              className="text-sm text-gray-700 transition-colors duration-300 hover:text-gray-900"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LoginPage;
