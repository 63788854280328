import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FaTimes, FaSave, FaCheck, FaTrash } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Button from '../ui/Button';
import Card from '../ui/Card';

// Mock current user (replace with actual user data as needed)
const mockCurrentUser = {
  uid: 'teacher123',
  name: 'John Doe',
};

// Function to generate mock tests based on subject and year level
const generateMockTests = (subject, yearLevel) => {
  const tests = [];
  for (let i = 1; i <= 10; i++) {
    tests.push({
      id: `${subject.toLowerCase()}-test-${yearLevel}-${i}`,
      name: `${subject} Test ${i} - Year ${yearLevel}`,
      subject,
      yearLevel,
      type: 'Test', // You can vary the type if needed
    });
  }
  return tests;
};

const Tests = ({ onClose, classID }) => {
  const [testsList, setTestsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // States for assigning tests
  const [selectedTests, setSelectedTests] = useState([]);
  const [assignMessage, setAssignMessage] = useState('');
  const [isAssigning, setIsAssigning] = useState(false);

  // New states for subject and year level
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedYearLevel, setSelectedYearLevel] = useState('');

  // Subjects available
  const subjects = ['Maths', 'English', 'Reading', 'Writing', 'Other'];

  // Year levels available (modify as per your requirement)
  const yearLevels = ['Year 1', 'Year 2', 'Year 3', 'Year 4', 'Year 5'];

  // Fetch tests based on selected subject and year level
  const fetchTests = useCallback(async () => {
    if (!selectedSubject || !selectedYearLevel) {
      setTestsList([]);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Simulate network delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Generate 10 mock tests based on subject and year level
      const generatedTests = generateMockTests(selectedSubject, selectedYearLevel);
      setTestsList(generatedTests);
    } catch (err) {
      console.error('Error fetching tests:', err);
      setError('Failed to load tests. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedSubject, selectedYearLevel]);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  // Handle deleting a test
  const handleDeleteTest = useCallback(
    (testId) => {
      const confirmDelete = window.confirm('Are you sure you want to delete this test?');
      if (!confirmDelete) return;

      try {
        // Simulate deletion by removing from state
        setTestsList((prevTests) => prevTests.filter((test) => test.id !== testId));
        setAssignMessage('Test deleted successfully!');
      } catch (err) {
        console.error('Error deleting test:', err);
        setAssignMessage('Failed to delete test. Please try again.');
      } finally {
        setTimeout(() => setAssignMessage(''), 3000);
      }
    },
    []
  );

  // Handle selecting/deselecting a test for assignment
  const toggleSelectTest = (test) => {
    setSelectedTests((prevSelected) => {
      if (prevSelected.find((t) => t.id === test.id)) {
        return prevSelected.filter((t) => t.id !== test.id);
      } else {
        return [...prevSelected, test];
      }
    });
  };

  // Handle assigning selected tests
  const handleAssignTests = useCallback(() => {
    if (selectedTests.length === 0) {
      setAssignMessage('No tests selected for assignment.');
      return;
    }

    setIsAssigning(true);
    setAssignMessage('');

    try {
      // Simulate assignment logic
      // For example, mark tests as assigned in local state
      setTestsList((prevTests) =>
        prevTests.map((test) =>
          selectedTests.find((t) => t.id === test.id)
            ? { ...test, assigned: true, assignedTo: mockCurrentUser.uid }
            : test
        )
      );

      setAssignMessage(`Successfully assigned ${selectedTests.length} test(s).`);
      setSelectedTests([]);
    } catch (err) {
      console.error('Error assigning tests:', err);
      setAssignMessage('Failed to assign tests. Please try again.');
    } finally {
      setIsAssigning(false);
      setTimeout(() => setAssignMessage(''), 3000);
    }
  }, [selectedTests]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className="relative w-full max-w-5xl max-h-full p-6 overflow-y-auto bg-white rounded-lg shadow-lg">
        <button
          onClick={onClose}
          className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
          aria-label="Close"
        >
          <FaTimes />
        </button>

        <div className="mb-6 text-center">
          <h2 className="text-2xl font-semibold">Assign Assessments</h2>
        </div>

        {/* Subject Buttons */}
        <div className="flex flex-wrap justify-center mb-4 space-x-2">
          {subjects.map((subject) => (
            <Button
              key={subject}
              onClick={() => setSelectedSubject(subject)}
              className={`px-4 py-2 m-1 ${
                selectedSubject === subject
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              } rounded`}
            >
              {subject}
            </Button>
          ))}
        </div>

        {/* Year Level Selector */}
        <div className="flex justify-center mb-6">
          <select
            value={selectedYearLevel}
            onChange={(e) => setSelectedYearLevel(e.target.value)}
            className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          >
            <option value="">Select Year Level</option>
            {yearLevels.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>

        {/* Tests List */}
        <div>
          {loading && <p>Loading assessments...</p>}
          {error && <p className="text-red-500">{error}</p>}
          {!loading && !error && selectedSubject && selectedYearLevel && testsList.length === 0 && (
            <p>No assessments available. Please add assessments to assign.</p>
          )}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
            {testsList.map((test) => (
              <Card
                key={test.id}
                className={`p-4 cursor-pointer border ${
                  selectedTests.find((t) => t.id === test.id)
                    ? 'border-blue-500 shadow-lg'
                    : 'border-gray-300'
                } rounded-lg flex flex-col justify-between`}
                onClick={() => toggleSelectTest(test)}
              >
                <div>
                  <h3 className="text-lg font-semibold">{test.name}</h3>
                  <p className="text-sm text-gray-600">
                    Subject: {test.subject} | {test.yearLevel}
                  </p>
                  {test.assigned && (
                    <p className="mt-2 text-xs text-green-500">Assigned to {mockCurrentUser.name}</p>
                  )}
                </div>
                <div className="flex items-center justify-between mt-4">
                  {selectedTests.find((t) => t.id === test.id) ? (
                    <FaCheck className="text-blue-500" />
                  ) : (
                    <span className="text-gray-400">Select to Assign</span>
                  )}
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteTest(test.id);
                    }}
                    className="text-red-500 hover:text-red-700"
                    aria-label="Delete Test"
                  >
                    <FaTrash />
                  </button>
                </div>
              </Card>
            ))}
          </div>
        </div>

        {/* Assignment Controls */}
        <div className="flex justify-end mt-6 space-x-4">
          <Button
            onClick={handleAssignTests}
            className={`px-6 py-2 text-white bg-green-500 rounded hover:bg-green-600 flex items-center ${
              isAssigning || selectedTests.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isAssigning || selectedTests.length === 0}
          >
            <FaSave className="mr-2" />
            {isAssigning ? 'Assigning...' : 'Assign Selected'}
          </Button>
          <Button
            onClick={onClose}
            className="px-6 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </Button>
        </div>

        {assignMessage && (
          <p className="mt-4 text-sm text-center text-green-600">{assignMessage}</p>
        )}
      </div>
    </div>
  );
};

// PropTypes for validation
Tests.propTypes = {
  onClose: PropTypes.func.isRequired,
  classID: PropTypes.string.isRequired,
};

export default Tests;
