/* src/components/loginpage/About.js */

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // For SEO
import { motion } from 'framer-motion'; // For animations

// Import Layout
import Layout from '../HeaderFooterLayoutBees/Layout'; // Import the Layout component

// Import Profile Picture
import ProfilePic from '../../assets/profilepic.jpg'; // Correct path to the profile picture

// Import Bee Component
import Bee from '../HeaderFooterLayoutBees/Bee'; // Ensure the correct path

// AboutPageData (Centralizing content)
const aboutData = {
  mission: {
    title: 'The Vision',
    content:
      'At Quick Thinkers, our vision is to empower educators and institutions with innovative tools that enhance the learning experience. We strive to simplify administrative tasks, foster engagement, and promote data-driven decision-making to create effective and dynamic educational environments. Our goal is to provide a one-stop shop for teaching and learning, providing quick and simple resources to free up teacher time so that teachers can do what they do best: teach.',
  },
  story: {
    title: 'The Story So Far',
    content:
      'Founded in 2024, Quick Thinkers was built from the ground up from a teaching perspective. As a teacher and technology learning specialist, I could never find the perfect tool. There was always a wish list of features that would make me more efficient. So, I decided to build my own. This is the early days of Quick Thinkers, and I would love to hear from you if you would like to be a part of it. I would love to hear what you think can support you to teach in the classroom.',
  },
  bio: {
    name: 'About',
    role: 'Founder & Teacher',
    content:
      'Hi, I’m Jeremy, a teacher and technology learning specialist who founded Quick Thinkers to provide teachers with efficient tools and resources. With years of experience in education, I understand the challenges that teachers face daily and am committed to creating solutions that truly support educators in their roles.',
  },
  whyBees: {
    title: 'Why the bees?',
    content: `Bees are amazing team players, just like students in a classroom. They work together, communicate, and build something bigger than themselves, which is what learning is all about. In classrooms, everyone contributes, just like in a beehive, where each bee has a role to play.

Bees are also in trouble. Their numbers are dropping fast, which affects the whole environment. Hopefully Quick Thinkers can raise some awareness and help save the bees.

So, bees represent teamwork, learning, and taking care of our world. 

Values that fit perfectly in any classroom.`,
  },
};

const About = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.slice(1); // Remove the '#' from the hash
      const element = document.getElementById(id);
      if (element) {
        // Scroll to the element smoothly
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });

        // Set focus for accessibility
        element.setAttribute('tabindex', '-1'); // Make it focusable
        element.focus();
      }
    }
  }, [location]);

  return (
    <Layout>
      <div className="relative flex flex-col h-auto overflow-hidden font-sans bg-yellow-50">
        {/* Bees Overlay */}
        <div className="absolute inset-0 z-30 pointer-events-none">
          {/* Define the number of bees */}
          {Array.from({ length: 10 }, (_, i) => (
            <Bee key={`bee-${i}`} id={i} />
          ))}
        </div>

        {/* SEO Optimization */}
        <Helmet>
          <title>About Us | Quick Thinkers</title>
          <meta
            name="description"
            content="Learn more about Quick Thinkers, our mission, story, and the dedicated team behind our innovative educational solutions."
          />
          <meta
            name="keywords"
            content="Quick Thinkers, about us, mission, educational technology, team, company story"
          />
          <link rel="canonical" href="https://www.quickthinkers.com/about" />
        </Helmet>

        {/* Main Content */}
        <main className="relative z-10 flex-grow">
          <section className="relative px-6 py-8"> {/* Reduced padding from py-12 to py-8 */}
            <div className="max-w-4xl mx-auto"> {/* Changed max-w-7xl to max-w-4xl for narrower container */}
              {/* Vision, Story, Bio, and Why the Bees inside the white box */}
              <div id="vision-story-bio" className="mb-12"> {/* Reduced mb-16 to mb-12 */}
                <motion.div
                  className="p-6 bg-white rounded-lg shadow-md"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5 }}
                >
                  {/* Jeremy's Bio */}
                  <div className="mb-8 text-center"> {/* Reduced mb-10 to mb-8 */}
                    <h2 className="mb-4 text-4xl font-bold text-yellow-500 md:text-5xl">
                      {aboutData.bio.name}
                    </h2>
                    {/* Enlarged Circular Profile Picture */}
                    <img
                      src={ProfilePic}
                      alt={`${aboutData.bio.name} - ${aboutData.bio.role}`}
                      className="object-cover w-40 h-40 mx-auto mb-4 rounded-full shadow-lg"
                    />
                    <p className="text-lg text-gray-700 md:text-xl">
                      {aboutData.bio.content}
                    </p>
                  </div>

                  {/* Vision Section */}
                  <div className="mb-8 text-center"> {/* Reduced mb-10 to mb-8 */}
                    <h3 className="mb-4 text-3xl font-semibold text-yellow-500">
                      {aboutData.mission.title}
                    </h3>
                    <p className="text-gray-700 text-md md:text-lg">
                      {aboutData.mission.content}
                    </p>
                  </div>

                  {/* Our Story Section */}
                  <div className="mb-8 text-center"> {/* Reduced mb-10 to mb-8 */}
                    <h3 className="mb-4 text-3xl font-semibold text-yellow-500">
                      {aboutData.story.title}
                    </h3>
                    <p className="text-gray-700 text-md md:text-lg">
                      {aboutData.story.content}
                    </p>
                  </div>

                  {/* Why the Bees Section */}
                  <div className="mb-8 text-center"> {/* Added new section */}
                    <h3 className="mb-4 text-3xl font-semibold text-yellow-500">
                      {aboutData.whyBees.title}
                    </h3>
                    <p className="text-gray-700 whitespace-pre-line text-md md:text-lg">
                      {aboutData.whyBees.content}
                    </p>
                  </div>
                </motion.div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

// AnimatedBee Component
const AnimatedBee = ({ id }) => (
  <motion.img
    src="/assets/beesolo.svg" // Ensure the correct path
    alt="Animated Bee"
    className="absolute w-12 h-12"
    initial={{ x: 0, y: 0 }}
    animate={{
      x: [0, 100, -100, 0],
      y: [0, -50, 50, 0],
    }}
    transition={{
      duration: 10,
      repeat: Infinity,
      ease: 'linear',
    }}
    style={{
      top: `${Math.random() * 100}%`,
      left: `${Math.random() * 100}%`,
      animationDelay: `${Math.random() * 5}s`,
    }}
    aria-hidden="true"
    key={id}
  />
);

export default About;
