// src/components/Students/apps/StudentYourHive.js

import React from 'react';

const StudentYourHive = () => {
  return (
    <div className="student-your-hive">
      <h2>Your Hive</h2>
      <p>This is where your hive content will go.</p>
    </div>
  );
};

export default StudentYourHive;
