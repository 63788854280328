import React from 'react';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import Card from '../ui/Card';

// SVG Components for Each Game
const HoneyHuntSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-yellow-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M12 2L15 8H9L12 2z" />
    <path d="M5 10h14v2H5v-2zM5 14h14v2H5v-2zM5 18h14v2H5v-2z" />
  </svg>
);

const BuzzBingoSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-blue-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
    <path d="M8 12h8M12 8v8" />
  </svg>
);

const QueensQuestSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-purple-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M12 2l2 6h6l-5 4 2 6-5-4-5 4 2-6-5-4h6z" />
  </svg>
);

const NectarNinjaSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-green-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M4 12l1.5 1.5L4 15l1.5 1.5L4 18" />
    <path d="M20 12l-1.5 1.5L20 15l-1.5 1.5L20 18" />
    <path d="M12 4v16" />
  </svg>
);

const HiveBuilderSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-orange-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <polygon points="12,2 2,7 12,12 22,7 12,2" />
    <polygon points="12,14 2,19 12,24 22,19 12,14" />
  </svg>
);

const PollenPuzzleSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-yellow-600"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <circle cx="12" cy="12" r="3" />
    <path d="M12 2v4M12 18v4M4.22 4.22l2.83 2.83M16.95 16.95l2.83 2.83M2 12h4M18 12h4M4.22 19.78l2.83-2.83M16.95 7.05l2.83-2.83" />
  </svg>
);

const BeeRunnerSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-indigo-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M5 12h14" />
    <path d="M12 5v14" />
    <circle cx="12" cy="12" r="3" />
  </svg>
);

const StingerStrikeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-red-500"
    viewBox="0 0 24 24"
    fill="currentColor"
    aria-hidden="true"
  >
    <polygon points="12,2 15,8 9,8 12,2" />
    <path d="M12 8v14" />
    <path d="M10 22h4" />
  </svg>
);

// Mapping game IDs to their respective SVG components
const gameSVGs = {
  '1': HoneyHuntSVG,
  '2': BuzzBingoSVG,
  '3': QueensQuestSVG,
  '4': NectarNinjaSVG,
  '5': HiveBuilderSVG,
  '6': PollenPuzzleSVG,
  '7': BeeRunnerSVG,
  '8': StingerStrikeSVG,
};

const Games = ({ onClose }) => {
  // Bee-themed games array with SVG association
  const beeGames = [
    { id: '1', name: 'Honey Hunt' },
    { id: '2', name: 'Buzz Bingo' },
    { id: '3', name: "Queen's Quest" },
    { id: '4', name: 'Nectar Ninja' },
    { id: '5', name: 'Hive Builder' },
    { id: '6', name: 'Pollen Puzzle' },
    { id: '7', name: 'Bee Runner' },
    { id: '8', name: 'Stinger Strike' },
  ];

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
      <div className="relative w-full max-w-5xl p-6 bg-white rounded-lg shadow-lg">
        <button
          onClick={onClose}
          className="absolute text-2xl text-gray-500 top-4 right-4 hover:text-gray-700"
          aria-label="Close"
        >
          <FaTimes />
        </button>

        <div className="mb-6 text-center">
          <h2 className="text-2xl font-semibold">Games</h2>
        </div>

        {/* Games List as Tiles */}
        <div>
          <h3 className="mb-4 text-xl font-semibold">Available Games</h3>
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {beeGames.map((game) => {
              const GameSVG = gameSVGs[game.id] || BeePlaceholder;
              return (
                <Card key={game.id} className="flex flex-col items-center p-4">
                  {/* Specific SVG Image */}
                  <div className="flex items-center justify-center w-full h-32 mb-4 bg-gray-100 rounded-lg">
                    <GameSVG />
                  </div>
                  <span className="mb-2 text-lg font-medium">{game.name}</span>
                  {/* Example Button */}
                  <button
                    className="px-4 py-2 text-white bg-yellow-500 rounded hover:bg-yellow-600"
                    onClick={() => alert(`Launching ${game.name}!`)}
                  >
                    Launch
                  </button>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

// Default SVG Placeholder in case a game doesn't have a specific SVG
const BeePlaceholder = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-16 h-16 text-gray-400"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 
         10-4.48 10-10S17.52 2 12 2zm0 
         18c-4.41 0-8-3.59-8-8s3.59-8 
         8-8 8 3.59 8 8-3.59 8-8 
         8z"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12 6v6l4 2"
    />
  </svg>
);

// PropTypes for validation
Games.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Games;
