// src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app'; // Corrected casing
import './index.css'; // Import global CSS (e.g., Tailwind CSS)
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async'; // Import HelmetProvider
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <AuthProvider> {/* Wrap AuthProvider outside BrowserRouter */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </HelmetProvider>
);

// Optional: If you want to measure performance
reportWebVitals();
