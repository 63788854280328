// src/components/TeacherLoginPage.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { auth, db } from '../../firebase'; // Ensure the path is correct based on your project structure
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify'; // Correctly import ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import CSS for react-toastify
import Header from './HeaderFooterLayoutBees/Header'; // Import the Header component
import Footer from './HeaderFooterLayoutBees/Footer'; // Import the Footer component

const TeacherLoginPage = () => {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState('');
  const [teacherPassword, setTeacherPassword] = useState('');
  const [teacherError, setTeacherError] = useState('');
  const [teacherLoading, setTeacherLoading] = useState(false);

  /**
   * Toggle between Sign Up and Login modes
   */
  const toggleSignUp = () => {
    setIsSignUp((prev) => !prev);
    setTeacherError('');
  };

  /**
   * Handle Teacher Login
   */
  const handleTeacherLogin = async () => {
    setTeacherError('');
    setTeacherLoading(true);
    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        teacherEmail,
        teacherPassword
      );
      const user = userCredential.user;

      // Reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', user.uid);

      // Update user data in Firestore
      await setDoc(
        userDocRef,
        {
          email: user.email,
          role: 'teacher',
          lastLogin: serverTimestamp(),
          isOnline: true,
        },
        { merge: true }
      );

      // Navigate to the teacher's homepage
      navigate('/teacher');
    } catch (error) {
      // Handle authentication errors
      setTeacherError(
        'Authentication failed. Please check your email and password.'
      );
      toast.error('Authentication failed. Please check your email and password.');
    } finally {
      setTeacherLoading(false);
    }
  };

  /**
   * Handle Teacher Sign-Up
   */
  const handleTeacherSignUp = async () => {
    setTeacherError('');
    setTeacherLoading(true);
    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        teacherEmail,
        teacherPassword
      );
      const user = userCredential.user;

      // Reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', user.uid);

      // Set user data in Firestore
      await setDoc(
        userDocRef,
        {
          email: user.email,
          role: 'teacher',
          createdAt: serverTimestamp(),
          isOnline: true,
        },
        { merge: true }
      );

      // Navigate to the teacher's homepage
      navigate('/teacher');
    } catch (error) {
      // Handle sign-up errors
      setTeacherError(error.message);
      toast.error(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };

  /**
   * Handle Google Sign-In
   */
  const handleGoogleSignIn = async () => {
    setTeacherError('');
    setTeacherLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      const user = result.user;

      // Reference to the user's document in Firestore
      const userDocRef = doc(db, 'users', user.uid);

      // Update user data in Firestore
      await setDoc(
        userDocRef,
        {
          email: user.email,
          role: 'teacher',
          lastLogin: serverTimestamp(),
          isOnline: true,
        },
        { merge: true }
      );

      // Navigate to the teacher's homepage
      navigate('/teacher');
    } catch (error) {
      // Handle Google sign-in errors
      setTeacherError(error.message);
      toast.error(error.message);
    } finally {
      setTeacherLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header */}
      <Header />

      {/* Main Content */}
      <main className="flex items-center justify-center flex-grow p-4">
        <div className="w-full max-w-md p-8 bg-gray-100 rounded-lg">
          <div className="flex flex-col space-y-4">
            <h2 className="text-2xl font-bold text-center">
              {isSignUp ? 'Teacher Sign Up' : 'Teacher Login'}
            </h2>
            {teacherError && (
              <p className="text-center text-red-500">{teacherError}</p>
            )}
            <input
              type="email"
              placeholder="Email"
              value={teacherEmail}
              onChange={(e) => setTeacherEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-600"
            />
            <input
              type="password"
              placeholder="Password"
              value={teacherPassword}
              onChange={(e) => setTeacherPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-600"
            />
            <button
              onClick={isSignUp ? handleTeacherSignUp : handleTeacherLogin}
              className={`w-full py-2 text-white transition duration-300 ease-in-out bg-yellow-500 rounded-md hover:bg-yellow-600 transform hover:scale-105 ${
                teacherLoading ? 'cursor-wait opacity-50' : ''
              }`}
              disabled={teacherLoading}
            >
              {teacherLoading
                ? isSignUp
                  ? 'Signing Up...'
                  : 'Logging In...'
                : isSignUp
                ? 'Sign Up'
                : 'Log In'}
            </button>
            {teacherLoading && (
              <p className="mt-4 text-center text-black">
                {isSignUp ? 'Signing up...' : 'Logging in...'}
              </p>
            )}
            <button
              onClick={handleGoogleSignIn}
              className={`w-full py-2 mt-2 text-white transition duration-300 ease-in-out bg-blue-600 rounded-md hover:bg-blue-700 transform hover:scale-105 ${
                teacherLoading ? 'cursor-wait opacity-50' : ''
              }`}
              disabled={teacherLoading}
            >
              {teacherLoading ? 'Processing...' : `Sign ${isSignUp ? 'Up' : 'In'} with Google`}
            </button>
            <p className="text-center">
              {isSignUp
                ? 'Already have an account?'
                : "Don't have an account?"}{' '}
              <span
                onClick={toggleSignUp}
                className="text-yellow-600 cursor-pointer hover:underline"
              >
                {isSignUp ? 'Log In' : 'Sign Up'}
              </span>
            </p>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer />

      {/* Toast Container */}
      <ToastContainer /> {/* Correctly placed ToastContainer */}
    </div>
  );
};

export default TeacherLoginPage;
