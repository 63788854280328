// src/components/Teachers/Toolbar.js

import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import Modal from '../../common/modal'; // Ensure correct import path

const Toolbar = ({ isVisible, toggleToolbar }) => {
  return (
    <>
      {/* Toolbar Container */}
      <div
        className={`fixed top-1/2 right-0 transform -translate-y-1/2 transition-transform duration-500 bg-white shadow-lg bg-opacity-90 backdrop-filter backdrop-blur-lg flex flex-col space-y-2 p-2 rounded-l-lg ${
          isVisible ? 'translate-x-0' : 'translate-x-full'
        }`}
        style={{ zIndex: 61 }} // Ensures toolbar overlays other components
      >
        {/* Points Button */}
        <button
          onClick={() => alert('Points functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-green-500 rounded-full hover:bg-green-600"
        >
          Points
        </button>

        {/* Links Button */}
        <button
          onClick={() => alert('Links functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-yellow-500 rounded-full hover:bg-yellow-600"
        >
          Links
        </button>

        {/* Timer Button */}
        <button
          onClick={() => alert('Timer functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-red-500 rounded-full hover:bg-red-600"
        >
          Timer
        </button>

        {/* Clock Button */}
        <button
          onClick={() => alert('Clock functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-purple-500 rounded-full hover:bg-purple-600"
        >
          Clock
        </button>

        {/* Groups Button */}
        <button
          onClick={() => alert('Groups functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-teal-500 rounded-full hover:bg-teal-600"
        >
          Groups
        </button>

        {/* Random Student Button */}
        <button
          onClick={() => alert('Random Student functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-pink-500 rounded-full hover:bg-pink-600"
        >
          Random
        </button>

        {/* Poll Button */}
        <button
          onClick={() => alert('Poll functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-indigo-500 rounded-full hover:bg-indigo-600"
        >
          Poll
        </button>

        {/* Calendar Button */}
        <button
          onClick={() => alert('Calendar functionality not implemented yet.')}
          className="w-24 h-12 px-2 py-1 text-center text-white bg-gray-500 rounded-full hover:bg-gray-600"
        >
          Calendar
        </button>
      </div>

      {/* Toggle Button */}
      <button
        onClick={toggleToolbar}
        className={`fixed top-1/2 transform -translate-y-1/2 p-1 text-blue-500 transition-all duration-500 bg-white rounded-l-lg shadow-lg`} // Match duration with toolbar
        style={{ right: isVisible ? '100px' : '0px', zIndex: 60 }} // Ensure toggle button is above the toolbar
        aria-label={isVisible ? 'Close Toolbar' : 'Open Toolbar'}
        aria-expanded={isVisible}
      >
        {isVisible ? <ChevronRight size={40} /> : <ChevronLeft size={40} />}
      </button>
    </>
  );
};

export default Toolbar;
