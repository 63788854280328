/* src/components/loginpage/Solutions.js */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async'; // For SEO
import { CheckCircle } from 'lucide-react'; // Import CheckCircle icon
import { motion } from 'framer-motion'; // For animations

// Import Assets
import Bee from '../../assets/beesolo.svg'; // Animated Bee SVG

// Import Layout
import Layout from '../HeaderFooterLayoutBees/Layout'; // Import the Layout component

// Sample data for different solutions
const solutionsData = {
  educators: {
    title: 'Solutions for Educators',
    description:
      'Quick Thinkers equips educators with innovative tools to create engaging and effective learning environments. Our platform simplifies administrative tasks, allowing teachers to focus more on teaching and less on paperwork.',
    features: [
      'Design dynamic lessons that captivate students\' attention.',
      'Receive instant insights into student understanding and progress.',
      'Streamline grading with our intelligent automation tools.',
      'Foster teamwork and collaboration among educators.',
      'Access a library of resources to enhance teaching skills.',
      'Tailor lesson plans and materials to fit your unique teaching style.',
    ],
    benefits: [
      'Increase student engagement and participation.',
      'Reduce administrative workload, allowing more time for instruction.',
      'Enhance the overall learning experience with interactive content.',
      'Facilitate better communication between teachers, students, and parents.',
    ],
    testimonials: [
      {
        name: 'Jane Doe',
        role: 'High School Teacher',
        feedback:
          'Quick Thinkers has transformed my classroom experience. The interactive lesson plans keep my students engaged, and the automated grading system saves me countless hours each week.',
      },
      {
        name: 'John Smith',
        role: 'Middle School Educator',
        feedback:
          'The real-time feedback feature allows me to address student needs promptly. It\'s an invaluable tool for any educator looking to enhance their teaching methods.',
      },
    ],
  },
  'schools-districts': {
    title: 'Solutions for Schools & Districts',
    description:
      'Empower your schools and districts with Quick Thinkers\' comprehensive management tools. Our platform provides data-driven insights, scalable solutions, and seamless integration to support both educators and students effectively.',
    features: [
      'Advanced Analytics Dashboards: Gain actionable insights with our robust data visualization tools.',
      'Customizable Curriculum Integration: Easily incorporate your existing curriculum into our platform.',
      'District-wide Communication Platforms: Enhance communication between administrators, teachers, and parents.',
      'Resource Allocation Optimization: Efficiently manage and distribute resources across your district.',
      'Student Information Systems: Keep track of student data securely and efficiently.',
      'Compliance Management: Ensure your district meets all educational standards and regulations.',
    ],
    benefits: [
      'Improve operational efficiency across all schools in the district.',
      'Enhance data-driven decision-making with comprehensive analytics.',
      'Foster better communication and collaboration among stakeholders.',
      'Ensure consistent and high-quality education standards.',
    ],
    testimonials: [
      {
        name: 'Sarah Lee',
        role: 'District Superintendent',
        feedback:
          'Quick Thinkers has streamlined our district operations. The analytics dashboards provide us with the data we need to make informed decisions, and the communication platform has improved our overall efficiency.',
      },
      {
        name: 'Michael Brown',
        role: 'School Administrator',
        feedback:
          'Managing resources across multiple schools used to be a challenge. With Quick Thinkers, we can allocate resources more effectively and ensure that each school has what it needs to succeed.',
      },
    ],
  },
  tutors: {
    title: 'Solutions for Tutors',
    description:
      'Enhance your tutoring services with Quick Thinkers\' suite of tools designed for personalized learning. Our platform supports progress tracking, interactive modules, and flexible scheduling to create meaningful student-tutor interactions.',
    features: [
      'Personalized Session Planning: Customize each tutoring session to meet individual student needs.',
      'Progress Tracking and Reporting: Monitor and report student progress with detailed analytics.',
      'Interactive Tutoring Modules: Utilize engaging modules that cater to various learning styles.',
      'Flexible Scheduling Tools: Manage your tutoring schedule with ease and accommodate student availability.',
      'Resource Library Access: Provide students with access to a vast library of educational resources.',
      'Secure Communication Channels: Maintain secure and private communication with your students.',
    ],
    benefits: [
      'Deliver tailored tutoring experiences that address each student\'s unique challenges.',
      'Track and demonstrate student progress to build trust and showcase effectiveness.',
      'Increase student engagement with interactive and diverse learning materials.',
      'Simplify scheduling to maximize your tutoring efficiency and availability.',
    ],
    testimonials: [
      {
        name: 'Emily Clark',
        role: 'Private Tutor',
        feedback:
          'Quick Thinkers has revolutionized the way I conduct my tutoring sessions. The personalized session planning tools allow me to tailor each lesson to my students\' needs, making my tutoring more effective.',
      },
      {
        name: 'David Wilson',
        role: 'Online Tutor',
        feedback:
          'The progress tracking feature is fantastic. I can easily monitor my students\' improvements and provide them with detailed reports, which has significantly boosted their confidence and performance.',
      },
    ],
  },
  'higher-ed': {
    title: 'Solutions for Higher Education',
    description:
      'Quick Thinkers supports higher education institutions with advanced academic tools, research collaboration platforms, and comprehensive support systems. Our solutions are designed to enhance both teaching and research capabilities.',
    features: [
      'Research Collaboration Platforms: Facilitate collaboration between researchers and institutions.',
      'Advanced Data Security: Protect sensitive academic and research data with top-tier security measures.',
      'Customizable Academic Support Tools: Tailor support systems to meet the unique needs of your institution.',
      'Integration with Existing Systems: Seamlessly integrate with your current educational technologies.',
      'Virtual Classroom Solutions: Provide robust virtual learning environments for remote education.',
      'Alumni Engagement Tools: Maintain strong connections with alumni through dedicated engagement platforms.',
    ],
    benefits: [
      'Enhance research productivity and collaboration across departments.',
      'Ensure the highest level of data security for academic and research information.',
      'Provide flexible and customizable support systems to meet diverse institutional needs.',
      'Facilitate effective remote learning with comprehensive virtual classroom solutions.',
    ],
    testimonials: [
      {
        name: 'Dr. Laura Martinez',
        role: 'University Research Coordinator',
        feedback:
          'Quick Thinkers\' research collaboration platform has significantly improved our ability to work with partners both locally and internationally. The data security measures give us peace of mind knowing our research data is protected.',
      },
      {
        name: 'Prof. Kevin Turner',
        role: 'Professor of Computer Science',
        feedback:
          'Integrating Quick Thinkers with our existing systems was seamless. The virtual classroom solutions have been invaluable, especially during the shift to remote learning, ensuring our students continue to receive high-quality education.',
      },
    ],
  },
};

// FeatureItem Component
const FeatureItem = ({ text }) => (
  <motion.div
    className="flex items-start p-4 space-x-3 transition-shadow duration-300 bg-white rounded-lg shadow hover:shadow-lg"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <CheckCircle className="w-6 h-6 mt-1 text-green-500" />
    <p className="text-gray-700">{text}</p>
  </motion.div>
);

// TestimonialItem Component
const TestimonialItem = ({ testimonial }) => (
  <motion.div
    className="p-6 transition-shadow duration-300 bg-white rounded-lg shadow-md hover:shadow-xl"
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <p className="mb-4 italic text-gray-600">"{testimonial.feedback}"</p>
    <div className="flex items-center">
      <div className="flex-shrink-0 w-12 h-12 mr-4 bg-gray-200 rounded-full"></div>
      <div>
        <h5 className="text-lg font-semibold text-gray-800">{testimonial.name}</h5>
        <p className="text-sm text-gray-500">{testimonial.role}</p>
      </div>
    </div>
  </motion.div>
);

// BenefitsSection Component
const BenefitsSection = ({ benefits }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">Benefits</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {benefits.map((benefit, index) => (
        <motion.div
          key={index}
          className="flex items-start p-4 space-x-3 bg-yellow-100 rounded-lg"
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <CheckCircle className="w-6 h-6 mt-1 text-yellow-600" />
          <p className="text-gray-700">{benefit}</p>
        </motion.div>
      ))}
    </div>
  </motion.div>
);

// TestimonialsSection Component
const TestimonialsSection = ({ testimonials }) => (
  <motion.div
    className="mt-12"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
  >
    <h3 className="mb-6 text-2xl font-bold text-yellow-900">What Our Clients Say</h3>
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
      {testimonials.map((testimonial, index) => (
        <TestimonialItem key={index} testimonial={testimonial} />
      ))}
    </div>
  </motion.div>
);

// AnimatedBee Component
const AnimatedBee = () => (
  <motion.img
    src={Bee}
    alt="Animated Bee"
    className="absolute w-12 h-12"
    initial={{ x: 0, y: 0 }}
    animate={{
      x: [0, 100, -100, 0],
      y: [0, -50, 50, 0],
    }}
    transition={{
      duration: 10,
      repeat: Infinity,
      ease: 'linear',
    }}
  />
);

const Solutions = () => {
  const location = useLocation();

  // Extract the hash from the URL and remove the '#' symbol
  const hash = location.hash.replace('#', '').toLowerCase();

  // Determine the selected category based on the hash
  const selectedCategory = solutionsData[hash] ? hash : null;

  return (
    <Layout>
      <div className="relative flex flex-col min-h-screen overflow-hidden font-sans bg-yellow-50">
        {/* Animated Bees */}
        <AnimatedBee />
        <AnimatedBee style={{ top: '20%', left: '10%' }} />
        <AnimatedBee style={{ top: '50%', left: '80%' }} />
        <AnimatedBee style={{ top: '70%', left: '30%' }} />

        {/* SEO Optimization */}
        <Helmet>
          <title>Solutions | Quick Thinkers</title>
          <meta
            name="description"
            content="Discover Quick Thinkers' innovative solutions for educators, schools, tutors, and higher education."
          />
        </Helmet>

        {/* Main Content */}
        <main className="z-10 flex-grow">
          <section className="relative px-6 py-12 bg-yellow-50 ">
            <div className="mx-auto max-w-7xl">
              {/* Render Only the Selected Category */}
              {selectedCategory ? (
                <div
                  className="p-8 mb-16 bg-white rounded-lg shadow-lg"
                >
                  <div className="mb-10 text-center">
                    <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">
                      {solutionsData[selectedCategory].title}
                    </h2>
                    <p className="text-lg text-gray-700 md:text-xl">
                      {solutionsData[selectedCategory].description}
                    </p>
                  </div>

                  {/* Key Features */}
                  <div className="mb-12">
                    <h3 className="mb-6 text-2xl font-semibold text-yellow-900">Key Features</h3>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                      {solutionsData[selectedCategory].features.map((feature, index) => (
                        <FeatureItem key={index} text={feature} />
                      ))}
                    </div>
                  </div>

                  {/* Benefits Section */}
                  <BenefitsSection benefits={solutionsData[selectedCategory].benefits} />

                  {/* Testimonials Section */}
                  <TestimonialsSection testimonials={solutionsData[selectedCategory].testimonials} />
                </div>
              ) : (
                // Optional: Display a message or default content when no category is selected
                <div className="p-8 mb-16 bg-white rounded-lg shadow-lg">
                  <div className="mb-10 text-center">
                    <h2 className="mb-4 text-3xl font-bold text-yellow-900 md:text-4xl">
                      Welcome to Our Solutions
                    </h2>
                    <p className="text-lg text-gray-700 md:text-xl">
                      Please select a category from the menu to view its solutions.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default Solutions;
