// src/components/Teachers/HexagonButton.js

import React from 'react';
import './HexagonButton.css';

// Existing HexagonButton Component
const HexagonButton = ({ label, onClick }) => {
  return (
    <div
      className="hexagon-button"
      onClick={onClick}
      role="button"
      aria-label={label}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') onClick();
      }}
    >
      <svg
        viewBox="0 0 1568 1848"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        className="hexagon-svg"
        aria-hidden="true"
      >
        {/* SVG paths */}
        <g>
          <title>Hexagon Layer</title>
          <path
            d="m781.25,50.15357l20,0l17,2l10,3l13,4l24,12l10,7l11,6l16,9l23,13l18,10l14,8l9,6l23,13l24,13l20,12l11,7l18,10l17,10l11,6l7,5l10,5l10,6l16,9l13,7l17,10l10,6l14,8l6,4l10,5l18,10l24,15l9,5l35,19l18,11l13,8l16,9l27,15l11,7l10,5l14,9l18,10l25,14l11,7l10,6l19,10l19,11l11,8l10,8l9,8l11,14l10,16l8,16l4,10l3,9l1,15l-6.25,726.25l-2,18l-6,16l-6,11l-7,11l-3,6l-662.71963,355.78505l-26,15l-18,10l-13,4l-13,3l-15,0l-18,-2l3.95327,-0.26168l-758.69159,-434.6542l-0.29206,-756.11916l4,-8l4,-6l1,-8l7,-11l4,-8l12,-16l7,-8l11,-11l14,-9l7,-5l10,-5l12,-7l10,-5l6,-4l8,-4l10,-7l16,-9l15,-8l16,-9l14,-9l9,-5l7,-4l9,-5l10,-6l25,-14l6,-4l15,-8l25,-15l17,-9l11,-6l11,-7l12,-7l9,-6l10,-5l6,-4l7,-3l13,-8l10,-5l8,-6l10,-5l7,-5l9,-4l8,-5l15,-8l5,-4l10,-3l5,-6l15,-8l10,-6l23,-12l9,-6l14,-8l10,-6l22,-12l11,-7l15,-8l9,-6l10,-6l15,-8l20,-11l9,-6l8,-4l9,-6l11,-6l10,-6l16,-8l8,-3l15,-3l7,-3l12,-1z"
            fill="#DDDCDC"
            id="svg_1"
            stroke="none"
          />
          <path
            d="m778.00552,6l20,0l17,2l10,3l13,4l24,12l10,7l646,375l9,8l11,14l10,16l8,16l4,10l3,9l1,15l0,770l-2,18l-6,16l-6,11l-7,11l-20.75701,30.29907l-509.47664,326.8785l-195.96262,85.02804l-96.85981,-0.84112l-636.03738,-380.05608l-31.88785,-28.85047l-29.01869,-49.45794l-9,-20l-1,-8l0,-798l4,-8l5,-14l7,-11l4,-8l12,-16l7,-8l11,-11l14,-9l7,-5l10,-5l12,-7l10,-5l6,-4l8,-4l10,-7l16,-9l15,-8l16,-9l14,-9l9,-5l7,-4l9,-5l10,-6l25,-14l6,-4l15,-8l25,-15l17,-9l11,-6l11,-7l12,-7l9,-6l10,-5l6,-4l7,-3l13,-8l10,-5l8,-6l10,-5l7,-5l9,-4l8,-5l15,-8l5,-4l10,-3l5,-6l15,-8l10,-6l23,-12l9,-6l14,-8l10,-6l22,-12l11,-7l15,-8l9,-6l10,-6l15,-8l20,-11l9,-6l8,-4l9,-6l11,-6l10,-6l16,-8l8,-3l15,-3l7,-3l12,-1zm14,181l-15,2l-12,4l-16,8l-623,359l-10,6l-2,3l-1,5l-2,1l-1,10l0,639l1,42l14,14l9,7l9,6l16,9l11,6l579,334l10,6l14,7l6,1l18,0l13,-3l18,-11l609,-351l17,-12l5,-4l0,-2l4,-2l2,-3l1,-10l1,-38l0,-588l-1,-64l-9,-6l-585,-337l-25,-14l-24,-14l-15,-8l-8,-2l-8,0z"
            fill="#F8B83A"
            id="svg_2"
            stroke="none"
          />
          <path
            stroke="none"
            d="m788.17158,104.85858l7.74312,0l7.29518,3.03674l16.04085,8.09796l635.945,371.01682l8.4492,6.89527l2.44666,3.94002l1.27774,9.60683l0.53328,64.05144l-14.03574,-7.08572l-13.03319,-8.09796l-21.05361,-12.14694l-24.06127,-14.17143l-25.06383,-14.17143l-24.06127,-14.17143l-35.08936,-20.24491l-26.06638,-15.18368l-24.06127,-14.17143l-56.14297,-32.39185l-26.06638,-15.18368l-21.05361,-12.14694l-17.0434,-10.12245l-52.13276,-30.36736l-24.06127,-14.17143l-56.14297,-32.39185l-26.06638,-15.18368l-28.07149,-16.19592l-19.04851,-11.1347l-24.06127,-14.17143l-14.03574,-8.09796l-24.06127,-14.17143l-25.06383,-14.17143l-24.06127,-14.17143l-15.0383,-8.09796l-10.15352,-3.10135l-11.11341,-0.06461l-14.50502,3.51055l-21.37358,10.7901l-22.05617,12.14694l-15.0383,9.11021l-28.07149,16.19592l-24.06127,14.17143l-21.05361,12.14694l-24.06127,14.17143l-26.06638,15.18368l-28.07149,16.19592l-26.06638,15.18368l-24.06127,14.17143l-23.05872,13.15919l-27.06893,16.19592l-25.06383,14.17143l-17.0434,10.12245l-26.06638,15.18368l-28.07149,16.19592l-24.06127,14.17143l-26.06638,15.18368l-21.05361,12.14694l-26.06638,15.18368l-21.05361,12.14694l-24.06127,14.17143l-28.07149,16.19592l-24.06127,14.17143l-23.05872,13.15919l-24.06127,14.17143l-10.02553,6.07347l-5.01277,3.03674l-1.00255,-5.06123l0,-21.25715l1.00255,-41.50206l5.01277,-10.12245l9.02298,-10.12245l6.01532,-5.06123l6.01532,-3.03674l647.64931,-371.49402l4.01021,-2.02449l-0.00006,0.00003z"
            fill="#838585"
            id="svg_3"
          />
          <path
            stroke="none"
            d="m747.20365,1740.19696l26.37471,9.93189l32.98674,-0.88587l34.17431,-12.63939l677.31032,-411.60437l14.43279,-10.889l10.30914,-9.8001l10.30914,-10.889l9.27822,-9.8001l-1.03091,38.11152l-2.06183,13.06681l-7.2164,26.13361l-4.12365,8.7112l-13.40188,13.06681l-15.46371,11.9779l-677.31032,413.78217l-18.55645,10.889l-13.40188,4.3556l-22.07312,4.28437l-20.94788,-2.86881l-19.58735,-84.93422z"
            fill="#838585"
            id="svg_4"
          />
          <path
            stroke="none"
            d="m764.24142,1825.96657l24.43241,0.40201l-5.97244,-64.44266l-723.94276,-429.21166l-14.43433,-10.76047l-10.31023,-9.68443l-10.31023,-10.76047l-9.27921,-9.68443l1.03102,37.66166l2.06204,12.91257l7.21716,25.82514l4.1241,8.60838l13.4033,12.91257l15.46535,11.83652l682.20742,412.9206l14.38846,7.76214l9.91794,3.70254l0.00001,-0.00001z"
            fill="#838585"
            id="svg_34"
          />
        </g>
      </svg>
      <div className="hexagon-label">{label}</div>
    </div>
  );
};

// New Hexagons Component
const Hexagons = ({
  handleStartSlides,
  handleStartWhiteboard,
  handleStartBookQuest,
  handleStartAssessment,
  handleStartTeacherWorkbook,
  handleStartViewSites,
  handleStartGames,
  handleStartTests,
}) => {
  const hexagons = [
    {
      label: 'Slides',
      onClick: handleStartSlides,
      position: 'row-start-1 col-start-1',
      translateX: '-45%',
      translateY: '155%',
    },
    {
      label: 'White Board',
      onClick: handleStartWhiteboard,
      position: 'row-start-1 col-start-1',
      translateX: '45%',
      translateY: '155%',
    },
    {
      label: 'Your Hive',
      onClick: () => alert('Dashboard clicked'),
      position: 'row-start-1 col-start-1',
      translateX: '0%',
      translateY: '75%',
    },
    {
      label: 'Sites',
      onClick: handleStartViewSites,
      position: 'row-start-1 col-start-1',
      translateX: '90%',
      translateY: '75%',
    },
    {
      label: 'Tests',
      onClick: handleStartTests,
      position: 'row-start-1 col-start-1',
      translateX: '-45%',
      translateY: '-5%',
    },
    {
      label: 'Journal',
      onClick: handleStartTeacherWorkbook,
      position: 'row-start-1 col-start-1',
      translateX: '-90%',
      translateY: '75%',
    },
    {
      label: 'Games',
      onClick: handleStartGames,
      position: 'row-start-1 col-start-1',
      translateX: '45%',
      translateY: '-5%',
    },
  ];

  return (
    <div className="grid items-center max-w-5xl grid-cols-1 grid-rows-1 gap-0 mx-auto mb-80 justify-items-center">
      {hexagons.map((hex, index) => (
        <div
          key={index}
          className={`transition-transform duration-300 hover:scale-105`}
          style={{
            gridRowStart: hex.position.split(' ')[0].split('-')[2],
            gridColumnStart: hex.position.split(' ')[1].split('-')[2],
            transform: `translate(${hex.translateX}, ${hex.translateY})`,
          }}
        >
          <HexagonButton label={hex.label} onClick={hex.onClick} />
        </div>
      ))}
    </div>
  );
};

export { HexagonButton, Hexagons };
