// src/firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; // Optional
import { getAuth } from "firebase/auth"; // For Authentication
import { getFirestore, serverTimestamp } from "firebase/firestore"; // For Firestore
import { getStorage } from "firebase/storage"; // For Storage (if needed)
import { getDatabase } from "firebase/database"; // Import Realtime Database

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLX7gtqiIqJKosz5EMG0fFCOQ_ICtC9pk",
  authDomain: "teaching-website-327e8.firebaseapp.com",
  projectId: "teaching-website-327e8",
  storageBucket: "teaching-website-327e8.appspot.com",
  messagingSenderId: "369682327209",
  appId: "1:369682327209:web:8b2a9d44ebb611f8c12bb8",
  measurementId: "G-DCRYPV5FQ4",
  databaseURL: "https://teaching-website-327e8-default-rtdb.firebaseio.com/", // Add your Realtime Database URL
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics (Optional)
const analytics = getAnalytics(app);

// Initialize Firebase Authentication
const auth = getAuth(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Storage (if needed)
const storage = getStorage(app);

// Initialize Firebase Realtime Database
const rtdb = getDatabase(app);

// Export the initialized services and serverTimestamp for use in your React components
export { db, auth, serverTimestamp, rtdb };
