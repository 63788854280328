import React, { useState } from 'react';
import Button from '../ui/Button';
import { CSSTransition } from 'react-transition-group';

const ViewSites = ({ show, handleClose }) => {
  const [url, setUrl] = useState('https://www.example.com'); // Default URL
  const [activeCategory, setActiveCategory] = useState('Learning Platforms');
  const [hoveredSite, setHoveredSite] = useState(null); // State for hovered site

  // Handle input URL changes
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  // Validate URL format
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  // Function to transform YouTube URLs to embed URLs
  const transformYouTubeUrl = (inputUrl) => {
    try {
      const parsedUrl = new URL(inputUrl);
      let videoId = '';

      if (parsedUrl.hostname === 'youtu.be') {
        // Shortened URL: https://youtu.be/VIDEO_ID
        videoId = parsedUrl.pathname.slice(1);
      } else if (
        parsedUrl.hostname === 'www.youtube.com' ||
        parsedUrl.hostname === 'youtube.com'
      ) {
        // Standard URL: https://www.youtube.com/watch?v=VIDEO_ID
        videoId = parsedUrl.searchParams.get('v');
      }

      if (videoId) {
        // You can add more parameters as needed
        return `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&modestbranding=1`;
      }

      // If no video ID is found, return the original URL
      return inputUrl;
    } catch (error) {
      // If URL parsing fails, return the original URL
      return inputUrl;
    }
  };

  // Handle the Load button click
  const handleLoad = () => {
    if (isValidUrl(url)) {
      // Check if the URL is a YouTube URL
      const lowerUrl = url.toLowerCase();
      if (
        lowerUrl.includes('youtube.com/watch') ||
        lowerUrl.includes('youtu.be/')
      ) {
        const embedUrl = transformYouTubeUrl(url);
        setUrl(embedUrl);
      } else {
        setUrl(url);
      }
    } else {
      alert('Please enter a valid URL.');
    }
  };

  // Define the categories and their respective sites
  const categories = [
    {
      name: 'Learning Platforms',
      sites: [
        {
          name: 'Google Classroom',
          url: 'https://classroom.google.com/',
          description:
            'A free web service for schools that simplifies creating, distributing, and grading assignments.',
        },
        {
          name: 'Khan Academy',
          url: 'https://www.khanacademy.org/',
          description:
            'A nonprofit with the mission to provide a free, world-class education for anyone, anywhere.',
        },
        {
          name: 'Coursera',
          url: 'https://www.coursera.org/',
          description:
            'An online learning platform offering courses, specializations, and degrees from top universities.',
        },
        {
          name: 'edX',
          url: 'https://www.edx.org/',
          description:
            'A massive open online course (MOOC) provider hosting university-level courses in a wide range of disciplines.',
        },
        {
          name: 'Udemy',
          url: 'https://www.udemy.com/',
          description:
            'An online learning platform aimed at professional adults and students, offering courses taught by industry experts.',
        },
        {
          name: 'Edmodo',
          url: 'https://www.edmodo.com/',
          description:
            'A communication platform for teachers, students, and parents to collaborate and share resources.',
        },
        // Add more Learning Platforms as needed with descriptions
      ],
    },
    {
      name: 'Coding and Programming',
      sites: [
        {
          name: 'Scratch',
          url: 'https://scratch.mit.edu/',
          description:
            'A free programming language and online community where you can create your own interactive stories, games, and animations.',
        },
        {
          name: 'Code.org',
          url: 'https://code.org/',
          description:
            'A nonprofit dedicated to expanding access to computer science in schools and increasing participation by women and underrepresented minorities.',
        },
        {
          name: 'Codecademy',
          url: 'https://www.codecademy.com/',
          description:
            'An interactive platform offering free coding classes in 12 different programming languages.',
        },
        {
          name: 'FreeCodeCamp',
          url: 'https://www.freecodecamp.org/',
          description:
            'A nonprofit community that helps you learn to code by building projects and earning certifications.',
        },
        {
          name: 'Codewars',
          url: 'https://www.codewars.com/',
          description:
            'A platform where you can train on coding challenges called kata to improve your skills.',
        },
        {
          name: 'W3Schools',
          url: 'https://www.w3schools.com/',
          description:
            'An educational website for learning web technologies online, covering topics like HTML, CSS, JavaScript, and more.',
        },
        {
          name: 'GitHub Education',
          url: 'https://education.github.com/',
          description:
            'Provides free access to developer tools and resources for students and educators.',
        },
        // Add more Coding and Programming sites as needed with descriptions
      ],
    },
    {
      name: 'Science and Technology',
      sites: [
        {
          name: 'NASA',
          url: 'https://www.nasa.gov/',
          description:
            'The United States government agency responsible for the civilian space program and for aeronautics and aerospace research.',
        },
        {
          name: 'National Geographic',
          url: 'https://www.nationalgeographic.com/',
          description:
            'A platform for exploration, education, and storytelling about the world’s natural wonders and cultures.',
        },
        {
          name: 'MIT OpenCourseWare',
          url: 'https://ocw.mit.edu/',
          description:
            'An initiative of the Massachusetts Institute of Technology to publish all of the educational materials from its courses online.',
        },
        {
          name: 'HowStuffWorks',
          url: 'https://www.howstuffworks.com/',
          description:
            'An educational website that explains the workings of various topics in science, technology, and everyday life.',
        },
        // Add more Science and Technology sites as needed with descriptions
      ],
    },
    {
      name: 'Mathematics',
      sites: [
        {
          name: 'Desmos',
          url: 'https://www.desmos.com/',
          description:
            'An advanced graphing calculator implemented as a web application and mobile application written in JavaScript.',
        },
        {
          name: 'Mathway',
          url: 'https://www.mathway.com/',
          description:
            'Provides step-by-step solutions to all kinds of math problems, from basic algebra to complex calculus.',
        },
        {
          name: 'Wolfram MathWorld',
          url: 'http://mathworld.wolfram.com/',
          description:
            'A comprehensive and interactive mathematics encyclopedia intended for students, educators, and researchers.',
        },
        {
          name: 'Khan Academy Math',
          url: 'https://www.khanacademy.org/math',
          description:
            'Offers practice exercises and instructional videos across a wide range of math topics.',
        },
        {
          name: 'Art of Problem Solving',
          url: 'https://artofproblemsolving.com/',
          description:
            'Provides a comprehensive online learning experience for students interested in mathematics.',
        },
        {
          name: 'Brilliant',
          url: 'https://brilliant.org/',
          description:
            'Offers interactive problem-solving and critical thinking courses in math, science, and engineering.',
        },
        // Add more Mathematics sites as needed with descriptions
      ],
    },
    {
      name: 'Literature and Languages',
      sites: [
        {
          name: 'Project Gutenberg',
          url: 'https://www.gutenberg.org/',
          description:
            'A library of over 60,000 free eBooks, focusing on older literary works where copyright has expired.',
        },
        {
          name: 'Duolingo',
          url: 'https://www.duolingo.com/',
          description:
            'A free language-learning platform offering courses in multiple languages through gamified lessons.',
        },
        {
          name: 'Poetry Foundation',
          url: 'https://www.poetryfoundation.org/',
          description:
            'Provides access to a vast collection of poems, poet biographies, and articles about poetry.',
        },
        {
          name: 'BBC Learning English',
          url: 'https://www.bbc.co.uk/learningenglish/',
          description:
            'Offers a variety of multimedia resources to help learners improve their English skills.',
        },
        {
          name: 'Memrise',
          url: 'https://www.memrise.com/',
          description:
            'Uses spaced repetition and mnemonic techniques to help users learn new languages and vocabulary.',
        },
        {
          name: 'Lingoda',
          url: 'https://www.lingoda.com/',
          description:
            'An online language school offering live classes with native-speaking teachers in multiple languages.',
        },
        // Add more Literature and Languages sites as needed with descriptions
      ],
    },
    {
      name: 'Arts and Music',
      sites: [
        {
          name: 'Chrome Music Lab',
          url: 'https://musiclab.chromeexperiments.com/',
          description:
            'A Google project that makes learning music more accessible through fun, hands-on experiments.',
        },
        {
          name: 'Smithsonian Learning Lab',
          url: 'https://learninglab.si.edu/',
          description:
            'Provides educators and students with access to Smithsonian’s vast collections and resources for learning.',
        },
        {
          name: 'ArtsEdge',
          url: 'https://artsedge.kennedy-center.org/',
          description:
            'Offers free educational resources for teaching the arts in the classroom.',
        },
        // Add more Arts and Music sites as needed with descriptions
      ],
    },
    {
      name: 'History and Social Studies',
      sites: [
        {
          name: 'History.com',
          url: 'https://www.history.com/',
          description:
            'The official website for the History Channel, providing historical information, documentaries, and articles.',
        },
        {
          name: 'National Geographic History',
          url: 'https://www.nationalgeographic.com/history/',
          description:
            'Features articles, videos, and interactive content about historical events and figures.',
        },
        {
          name: 'Smithsonian National Museum of American History',
          url: 'https://americanhistory.si.edu/',
          description:
            'Offers virtual exhibits and resources related to American history and culture.',
        },
        // Add more History and Social Studies sites as needed with descriptions
      ],
    },
    // Add more categories as needed with descriptions
  ];

  return (
    <CSSTransition in={show} timeout={300} classNames="modal" unmountOnExit>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative flex flex-col w-11/12 max-h-screen bg-white rounded-lg shadow-lg h-5/6">
          {/* Header */}
          <header className="relative flex items-center justify-center p-4 border-b">
            <h2 className="text-2xl font-bold">Website Viewer</h2>
            <Button
              onClick={handleClose}
              variant="ghost"
              className="absolute right-4"
            >
              Close
            </Button>
          </header>

          {/* URL Input */}
          <div className="flex flex-col items-center p-4 space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
            <input
              type="text"
              value={url}
              onChange={handleUrlChange}
              placeholder="Enter website URL"
              className="flex-1 p-2 border rounded"
            />
            <Button onClick={handleLoad}>Load</Button>
          </div>

          {/* Categories Tabs */}
          <div className="border-b">
            <nav className="flex flex-wrap p-4">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={`px-4 py-2 text-sm whitespace-nowrap focus:outline-none ${
                    activeCategory === category.name
                      ? 'border-b-2 border-blue-500 text-blue-500'
                      : 'text-gray-600 hover:text-blue-500'
                  }`}
                  onClick={() => setActiveCategory(category.name)}
                >
                  {category.name}
                </button>
              ))}
            </nav>

            {/* Description Container */}
            <div className="p-4 bg-gray-100 border-t">
              {hoveredSite ? (
                <p className="text-sm text-gray-700">{hoveredSite.description}</p>
              ) : (
                <p className="text-sm text-gray-500">
                  Hover over a site to see its description.
                </p>
              )}
            </div>
          </div>

          {/* Sites List */}
          <div className="p-4 overflow-x-auto">
            {categories.map(
              (category, index) =>
                activeCategory === category.name && (
                  <div key={index} className="flex flex-wrap space-x-2">
                    {category.sites.map((site, siteIndex) => (
                      <Button
                        key={siteIndex}
                        onClick={() => setUrl(site.url)}
                        onMouseEnter={() => setHoveredSite(site)}
                        onMouseLeave={() => setHoveredSite(null)}
                        className="mb-2"
                      >
                        {site.name}
                      </Button>
                    ))}
                  </div>
                )
            )}
          </div>

          {/* Iframe Display */}
          <div className="flex-1 p-4 overflow-auto">
            <iframe
              src={url}
              title="Website Viewer"
              className="w-full h-full border rounded"
              sandbox="allow-scripts allow-same-origin allow-forms"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default ViewSites;
