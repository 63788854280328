// src/components/Students/apps/StudentJournal.js

import React from 'react';

const StudentJournal = () => {
  return (
    <div className="student-journal">
      <h2>Journal</h2>
      <p>This is where the journal content will go.</p>
    </div>
  );
};

export default StudentJournal;

