// src/components/Students/apps/studentwhiteboard.js

import React, { useRef, useEffect } from 'react';

const StudentWhiteboard = () => { // Removed the handleClose prop
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    let drawing = false;

    const startDrawing = ({ nativeEvent }) => {
      drawing = true;
      draw(nativeEvent);
    };

    const finishDrawing = () => {
      drawing = false;
      context.beginPath();
    };

    const draw = ({ nativeEvent }) => {
      if (!drawing) return;
      const { offsetX, offsetY } = nativeEvent;
      context.lineWidth = 5;
      context.lineCap = 'round';
      context.strokeStyle = 'black';

      context.lineTo(offsetX, offsetY);
      context.stroke();
      context.beginPath();
      context.moveTo(offsetX, offsetY);
    };

    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mouseup', finishDrawing);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mouseout', finishDrawing);

    // Clean up event listeners on unmount
    return () => {
      canvas.removeEventListener('mousedown', startDrawing);
      canvas.removeEventListener('mouseup', finishDrawing);
      canvas.removeEventListener('mousemove', draw);
      canvas.removeEventListener('mouseout', finishDrawing);
    };
  }, []);

  return (
    <div className="student-whiteboard">
      {/* Removed the Close button */}
      <canvas
        ref={canvasRef}
        width={800}
        height={600}
        // Removed the border style
      />
    </div>
  );
};

export default StudentWhiteboard;
