// src/components/ExcalidrawModal.js

import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Excalidraw, exportToBlob } from '@excalidraw/excalidraw';
import { useWhiteboardData } from './whiteboardData'; // Adjust the path as necessary

const ExcalidrawModal = ({ 
  show, 
  handleClose, 
  title = 'Whiteboard',
  initialData = null,
  onSave = () => {} 
}) => {
  const [excalidrawAPI, setExcalidrawAPI] = useState(null);
  
  // Ref for debouncing
  const debounceTimer = useRef(null);
  const DEBOUNCE_DELAY = 500; // milliseconds

  // Utilize the custom hook
  const { updateWhiteboardData, sendWhiteboardData } = useWhiteboardData();

  const handleCloseModal = useCallback(async () => {
    if (excalidrawAPI) {
      const elements = excalidrawAPI.getSceneElements();
      const blob = await exportToBlob({
        elements,
        mimeType: 'image/png',
        appState: excalidrawAPI.getAppState()
      });
      const data = { elements, blob };
      
      // Call the onSave prop if provided
      onSave(data);
      
      // Send blob data to whiteboardData.js for further handling
      sendWhiteboardData(data)
        .then(() => {
          console.log('Data successfully sent to RTDB.');
        })
        .catch(error => {
          console.error('Error sending data to RTDB:', error);
        });
    }
    handleClose();
  }, [handleClose, excalidrawAPI, onSave, sendWhiteboardData]);

  // Hide buttons and fix menu positioning
  useEffect(() => {
    if (show) {
      const hideButtonsAndFixMenu = () => {
        const selectors = [
          '.layer-ui__wrapper__top-right.zen-mode-transition',
          'button[title="Library"]',
          '[aria-label="Library"]',
          '.library-button',
          '.ToolIcon__library',
          '.help-icon',
          '[aria-label="Help"]',
          'button[title="Help"]',
          '.help-button',
          '.layer-ui__wrapper__footer-right button',
          'button[aria-label="Help"]',
          '.layer-ui__wrapper__footer-right [title="?"]',
          // Add selectors for social media links
          'a[aria-label="GitHub"]',
          'a[aria-label="Discord"]',
          'a[aria-label="Twitter"]',
          '.dropdown-menu-group',  // This will hide the entire "Excalidraw links" section
          'div[style*="height: 1px"]' // Hide the dividers
        ];

        selectors.forEach(selector => {
          const elements = document.querySelectorAll(selector);
          elements.forEach(el => {
            if (el) el.style.display = 'none';
          });
        });

        // Updated menu positioning
        const popover = document.querySelector('.Island.App-menu__popover');
        if (popover) {
          popover.style.left = 'auto';
          popover.style.right = 'auto';
          popover.style.transformOrigin = 'top left';
          popover.style.transform = 'none';
        }
      };

      // Create a MutationObserver to watch for changes
      const observer = new MutationObserver((mutations) => {
        hideButtonsAndFixMenu();
      });

      // Start observing the document with the configured parameters
      observer.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: true,
        attributeFilter: ['style', 'class']
      });

      // Updated custom styles
      const style = document.createElement('style');
      style.textContent = `
        .excalidraw .Island.App-menu__popover {
          position: absolute !important;
          left: auto !important;
          right: auto !important;
          transform: none !important;
          margin-left: 40px !important;
        }
        .excalidraw-wrapper {
          overflow: visible !important;
        }
        .layer-ui__wrapper {
          overflow: visible !important;
        }
        .excalidraw .popover {
          position: absolute !important;
          left: auto !important;
          right: auto !important;
          transform: none !important;
          margin-left: 40px !important;
        }
        .Island.dropdown-menu-container {
          position: absolute !important;
          left: auto !important;
          right: auto !important;
          transform: none !important;
          margin-left: 5px !important;
        }
        /* Hide social media links and their container */
        .dropdown-menu-group,
        a[aria-label="GitHub"],
        a[aria-label="Discord"],
        a[aria-label="Twitter"] {
          display: none !important;
        }
      `;
      document.head.appendChild(style);

      // Initial attempt to hide and fix
      hideButtonsAndFixMenu();

      // Cleanup
      return () => {
        observer.disconnect();
        document.head.removeChild(style);
      };
    }
  }, [show]);

  // Handle escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape' && show) {
        handleCloseModal();
      }
    };

    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, [show, handleCloseModal]);

  // Handle Excalidraw onChange for real-time updates
  const handleExcalidrawChange = useCallback(
    (elements, appState) => {
      // Debounce the updateWhiteboardData call
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = setTimeout(() => {
        // Prepare the data to send
        const data = { elements, appState };

        // Send real-time whiteboard data
        updateWhiteboardData(data)
          .then(() => {
            console.log('Real-time whiteboard data sent.');
          })
          .catch(error => {
            console.error('Error sending real-time whiteboard data:', error);
          });
      }, DEBOUNCE_DELAY);
    },
    [updateWhiteboardData]
  );

  return (
    <>
      {show && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-11/12 bg-white rounded-lg shadow-lg h-5/6" style={{ overflow: 'visible' }}>
            <div className="relative flex items-center justify-between w-full h-12 px-4 bg-gray-100 border-b">
              <h2 className="flex-grow text-xl font-semibold text-center">{title}</h2>
              <div className="flex gap-2">
                <button
                  onClick={handleCloseModal}
                  className="text-2xl font-bold text-gray-600 hover:text-gray-800 focus:outline-none"
                  aria-label="Close Modal"
                >
                  &times;
                </button>
              </div>
            </div>

            <div className="w-full h-[calc(100%-3rem)]" style={{ overflow: 'visible' }}>
              <Excalidraw
                onChange={handleExcalidrawChange}
                excalidrawAPI={setExcalidrawAPI}
                initialData={initialData}
                theme="light"
                gridModeEnabled={false}
                zenModeEnabled={false}
                viewModeEnabled={false}
                UIOptions={{
                  canvasActions: {
                    loadScene: false,
                    saveToActiveFile: false,
                    export: false,
                    saveAsImage: false,
                  },
                  libraries: false
                }}
                renderTopRightUI={() => null}
                viewBackgroundColor="#ffffff"
                detectScroll={false}
                handleKeyboardGlobally={false}
                libraryReturnUrl={null}
                autoFocus={true}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ExcalidrawModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  initialData: PropTypes.object,
  onSave: PropTypes.func
};

export default ExcalidrawModal;
