// src/utils/dataSanitizer.js

import _ from 'lodash';

/**
 * Recursively removes properties with undefined values from an object or array.
 *
 * @param {Object|Array} data - The data to sanitize.
 * @returns {Object|Array} - The sanitized data.
 */
export const sanitizeData = (data) => {
  if (Array.isArray(data)) {
    return data
      .map(item => sanitizeData(item))
      .filter(item => item !== undefined);
  } else if (data !== null && typeof data === 'object') {
    return _.pickBy(
      _.mapValues(data, value => sanitizeData(value)),
      value => value !== undefined
    );
  }
  return data;
};
