// src/components/Students/apps/StudentSlides.js

import React from 'react';

const StudentSlides = () => {
  return (
    <div className="student-slides">
      <h2>Slides</h2>
      <p>This is where the slides content will go.</p>
    </div>
  );
};

export default StudentSlides;
