// src/components/Students/apps/StudentGames.js

import React from 'react';

const StudentGames = () => {
  return (
    <div className="student-games">
      <h2>Games</h2>
      <p>This is where the games content will go.</p>
    </div>
  );
};

export default StudentGames;

