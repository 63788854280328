// src/contexts/StudentManager.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  ref,
  get,
  set,
  update,
  onValue,
  off,
} from 'firebase/database';
import { doc, getDoc } from 'firebase/firestore';
import { rtdb, db } from '../firebase'; // Ensure these are correctly exported from your firebase config
import { useAuth } from './AuthContext';

/**
 * Student Manager Context
 * Provides student-specific data and functions to manage student state.
 */
const StudentManagerContext = createContext();

/**
 * Custom hook to use the StudentManagerContext
 */
export const useStudentManager = () => {
  return useContext(StudentManagerContext);
};

/**
 * StudentManagerProvider Component
 * Wraps around components that need access to student data.
 */
export const StudentManagerProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [studentInfo, setStudentInfo] = useState({
    classId: null,
    studentName: null,
    status: 'absent', // Possible statuses: 'active', 'absent', 'venturing'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Fetch student mappings from RTDB based on the current user's UID.
   */
  const fetchStudentMapping = async (uid) => {
    try {
      const mappingRef = ref(rtdb, `studentMappings/${uid}`);
      const snapshot = await get(mappingRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setStudentInfo({
          classId: data.classID,
          studentName: data.studentName,
          status: 'active', // Assume active upon fetching
        });
      } else {
        setError('No student mapping found.');
      }
    } catch (err) {
      console.error('Error fetching student mapping:', err);
      setError(err.message);
    }
  };

  /**
   * Fetch additional student data from Firestore if needed.
   */
  const fetchStudentData = async (classId, studentName) => {
    try {
      // Example: Fetch additional data from Firestore if required
      // const studentDocRef = doc(db, 'classes', classId, 'students', studentName);
      // const studentDoc = await getDoc(studentDocRef);
      // if (studentDoc.exists()) {
      //   // Process additional student data
      // }
    } catch (err) {
      console.error('Error fetching additional student data:', err);
      setError(err.message);
    }
  };

  /**
   * Effect to fetch student mapping when the user is authenticated.
   */
  useEffect(() => {
    if (!currentUser) {
      setStudentInfo({
        classId: null,
        studentName: null,
        status: 'absent',
      });
      setIsLoading(false);
      return;
    }

    const uid = currentUser.uid;
    fetchStudentMapping(uid)
      .then(() => {
        if (studentInfo.classId && studentInfo.studentName) {
          fetchStudentData(studentInfo.classId, studentInfo.studentName);
        }
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // Cleanup function to unsubscribe from RTDB listeners if any
    return () => {
      // Example: If you set up any real-time listeners, detach them here
      // off(ref(rtdb, `studentMappings/${uid}`));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  /**
   * Update student status in RTDB.
   * @param {string} status - New status ('active', 'absent', 'venturing')
   */
  const updateStudentStatus = async (status) => {
    if (!currentUser) {
      setError('No authenticated user.');
      return;
    }

    if (!['active', 'absent', 'venturing'].includes(status)) {
      setError(`Invalid status: ${status}`);
      return;
    }

    try {
      const { classId, studentName } = studentInfo;
      if (!classId || !studentName) {
        throw new Error('Student class ID or name is missing.');
      }

      const classStatusRef = ref(rtdb, `activeclasses/${classId}/studentsStatus/${studentName}`);
      await set(classStatusRef, status);
      setStudentInfo((prev) => ({ ...prev, status }));
    } catch (err) {
      console.error('Error updating student status:', err);
      setError(err.message);
    }
  };

  /**
   * Real-time listener for student status changes in RTDB.
   */
  useEffect(() => {
    if (!studentInfo.classId || !studentInfo.studentName) return;

    const statusRef = ref(rtdb, `activeclasses/${studentInfo.classId}/studentsStatus/${studentInfo.studentName}`);

    const listener = onValue(statusRef, (snapshot) => {
      if (snapshot.exists()) {
        const newStatus = snapshot.val();
        setStudentInfo((prev) => ({ ...prev, status: newStatus }));
      }
    }, (err) => {
      console.error('Error listening to student status:', err);
      setError(err.message);
    });

    // Cleanup listener on unmount or when dependencies change
    return () => {
      off(statusRef, 'value', listener);
    };
  }, [studentInfo.classId, studentInfo.studentName]);

  return (
    <StudentManagerContext.Provider
      value={{
        studentInfo,
        isLoading,
        error,
        updateStudentStatus,
      }}
    >
      {children}
    </StudentManagerContext.Provider>
  );
};
