// src/components/Students/apps/StudentSites.js

import React from 'react';

const StudentSites = () => {
  return (
    <div className="student-sites">
      <h2>Sites</h2>
      <p>This is where the sites content will go.</p>
    </div>
  );
};

export default StudentSites;
