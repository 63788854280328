// src/components/MyLessonsTab.jsx

import React, { useState, useEffect } from 'react';
import Input from '../ui/Input';
import Card from '../ui/Card';
import Button from '../ui/Button';

// Placeholder data structure
// You can replace this with actual data passed via props or fetched from another source
const mockSlideshows = [
  {
    id: '1',
    title: 'Introduction to Algebra',
    description: 'Basic concepts of algebra.',
  },
  {
    id: '2',
    title: 'Advanced Geometry',
    description: 'In-depth study of geometric principles.',
  },
  // Add more mock slideshows as needed
];

const MyLessonsTab = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSlideshows, setFilteredSlideshows] = useState(mockSlideshows);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Simulate data fetching with a timeout
    const fetchSlideshows = async () => {
      setLoading(true);
      // Simulate a delay
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Filter slideshows based on search query
      if (searchQuery.trim() === '') {
        setFilteredSlideshows(mockSlideshows);
      } else {
        const filtered = mockSlideshows.filter((slideshow) =>
          slideshow.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredSlideshows(filtered);
      }
      setLoading(false);
    };

    fetchSlideshows();
  }, [searchQuery]);

  // Handler for importing a slideshow
  const handleImportSlideshow = (slideshow) => {
    // Implement the import functionality as needed
    console.log('Importing slideshow:', slideshow);
    // Example: You might navigate to a slideshow editor or add it to a list
  };

  return (
    <div>
      {/* Search Bar for My Lessons */}
      <div className="flex flex-col mb-8">
        <Input
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Search your lessons..."
          className="mb-6 text-base md:text-lg"
        />
      </div>

      {/* Library Slideshows */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {loading ? (
          <div className="flex items-center justify-center col-span-full">
            <span className="text-gray-600">Loading your lessons...</span>
          </div>
        ) : filteredSlideshows.length > 0 ? (
          filteredSlideshows.map((slideshow) => (
            <Card
              key={slideshow.id}
              className="p-6 transition-shadow duration-300 bg-white shadow-lg rounded-xl hover:shadow-xl"
            >
              <h3 className="mb-3 text-2xl font-semibold text-gray-800">
                {slideshow.title}
              </h3>
              <p className="mb-4 text-gray-600">{slideshow.description}</p>
              <div className="flex space-x-2">
                <Button
                  onClick={() => handleImportSlideshow(slideshow)}
                  className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Import Slideshow
                </Button>
                {/* Plan Button Removed */}
              </div>
            </Card>
          ))
        ) : (
          // No slideshows to display
          <div className="text-center text-gray-600 col-span-full">
            You have no lessons.
          </div>
        )}
      </div>
    </div>
  );
};

export default MyLessonsTab;
