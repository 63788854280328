// src/components/SlideshowApp.jsx

import React, { useState, useEffect } from 'react';
import {
  FaBook,
  FaChartBar,
  FaAtom,
  FaPaintBrush,
  FaLeaf,
  FaLanguage,
  FaFolder,
} from 'react-icons/fa';
import { MdScience, MdSportsBasketball } from 'react-icons/md';
import { GiAbstract050, GiBrain } from 'react-icons/gi';
import { IoIosPeople } from 'react-icons/io';
import Button from '../ui/Button';
import Card from '../ui/Card';
import Input from '../ui/Input';
import curriculumData from '../../../data/output.json';
import MyLessonsTab from './MyLessonsTab';

// Define a set of vibrant gradient backgrounds with varied directions
const gradientBackgrounds = [
  'bg-gradient-to-tr from-indigo-500 via-purple-500 to-pink-500', // Top-Right
  'bg-gradient-to-l from-green-400 to-blue-500', // Left
  'bg-gradient-to-b from-yellow-400 to-red-500', // Bottom
  'bg-gradient-to-bl from-teal-400 to-blue-500', // Bottom-Left
  'bg-gradient-to-r from-pink-400 to-purple-400', // Right
  'bg-gradient-to-t from-orange-400 to-pink-400', // Top
  'bg-gradient-to-tl from-yellow-400 to-pink-500', // Top-Left
  'bg-gradient-to-br from-blue-400 to-green-500', // Bottom-Right
  'bg-gradient-to-r from-rose-400 to-red-500', // Right
  'bg-gradient-to-l from-cyan-400 to-blue-500', // Left
];

// Map category names to diverse icons from various icon libraries
const iconMapping = {
  // Learning Areas
  Mathematics: <FaChartBar size={40} className="text-white" />,
  Science: <MdScience size={40} className="text-white" />,
  English: <FaBook size={40} className="text-white" />,
  'Social Studies': <IoIosPeople size={40} className="text-white" />,
  Arts: <FaPaintBrush size={40} className="text-white" />,
  // Subjects (Add more as needed)
  Algebra: <GiAbstract050 size={40} className="text-white" />,
  Geometry: <FaLeaf size={40} className="text-white" />,
  Biology: <GiBrain size={40} className="text-white" />,
  Chemistry: <FaAtom size={40} className="text-white" />,
  Literature: <FaLanguage size={40} className="text-white" />,
  Sports: <MdSportsBasketball size={40} className="text-white" />,
  // Default
  default: <FaFolder size={40} className="text-white" />,
};

// Fallback icon for categories without specific mappings
const defaultIcon = <FaFolder size={40} className="text-white" />;

// Helper function to remove consecutive duplicate names from node names
const removeDuplicateNames = (name) => {
  const parts = name.split(' / ');
  const uniqueParts = [];
  parts.forEach((part, index) => {
    if (index === 0 || part !== parts[index - 1]) {
      uniqueParts.push(part);
    }
  });
  return uniqueParts.join(' / ');
};

// Move searchNodes outside the component to fix useEffect dependency
const searchNodes = (nodes, query, path = []) => {
  const matches = [];

  nodes.forEach((node) => {
    const nodeNameMatches = node.name.toLowerCase().includes(query.toLowerCase());
    let matchedChildren = [];

    if (node.children) {
      matchedChildren = searchNodes(node.children, query, [...path, node]);
    }

    if (nodeNameMatches || matchedChildren.length > 0) {
      const newNode = { ...node };
      if (matchedChildren.length > 0) {
        newNode.children = matchedChildren;
      } else {
        delete newNode.children;
      }
      newNode.path = [...path, node]; // Store the path to this node
      matches.push(newNode);
    }
  });

  return matches;
};

const SlideshowApp = ({ onClose, classId }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryPath, setCategoryPath] = useState([]); // Array of nodes
  const [availableOptions, setAvailableOptions] = useState([]);
  const [selectedElaborationData, setSelectedElaborationData] = useState(null);
  const [highlightedElaboration, setHighlightedElaboration] = useState(null);
  const [isNavigatingForward, setIsNavigatingForward] = useState(true);
  const [activeTab, setActiveTab] = useState('Curriculum');

  // Placeholder for slideshows if needed
  // You can pass slideshows as props or manage them via context/state

  // Update available options based on categoryPath, activeTab, and searchQuery
  useEffect(() => {
    if (activeTab !== 'Curriculum') return; // Only update if on Curriculum tab

    const getAvailableOptions = () => {
      let currentNodes = curriculumData; // Start from the root nodes

      for (let i = 0; i < categoryPath.length; i++) {
        const selectedNode = categoryPath[i];
        if (selectedNode.children) {
          currentNodes = selectedNode.children;
        } else {
          currentNodes = [];
          break;
        }
      }

      if (searchQuery) {
        // Perform search over the current nodes
        currentNodes = searchNodes(currentNodes, searchQuery);
      }

      if (currentNodes && currentNodes.length > 0) {
        // Check if all nodes are leaf nodes (have data but no children)
        const allLeafNodes = currentNodes.every(
          (node) =>
            node.data &&
            node.data.length > 0 &&
            (!node.children || node.children.length === 0)
        );

        if (allLeafNodes) {
          // Display content descriptors directly
          setAvailableOptions(currentNodes);
        } else {
          // Display folders and/or content descriptors
          setAvailableOptions(currentNodes);
        }
      } else if (categoryPath.length > 0) {
        // If at a leaf node, check for data (elaborations)
        const lastNode = categoryPath[categoryPath.length - 1];
        if (lastNode.data && lastNode.data.length > 0) {
          setSelectedElaborationData({
            contentDesc: lastNode.name,
            elaborations: lastNode.data, // Now contains objects with 'CdCode' and 'Elaboration'
          });
          setHighlightedElaboration(null); // Reset any highlighted elaboration
        } else {
          setAvailableOptions([]);
        }
      } else {
        // At the root level
        setAvailableOptions(currentNodes);
      }
    };

    getAvailableOptions();
  }, [categoryPath, activeTab, searchQuery]);

  // Auto-Navigation Effect (updated)
  useEffect(() => {
    if (activeTab !== 'Curriculum') return; // Only auto-navigate on Curriculum tab
    if (searchQuery) return; // Do not auto-navigate during search

    const shouldAutoNavigate = () => {
      if (availableOptions.length === 1 && isNavigatingForward) {
        return true;
      }
      return false;
    };

    if (shouldAutoNavigate()) {
      handleSelectOption(availableOptions[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableOptions, activeTab]);

  // Handler for selecting a category option or elaboration
  const handleSelectOption = (option) => {
    console.log('Option Selected:', option); // Debug

    if (
      option.data &&
      option.data.length > 0 &&
      (!option.children || option.children.length === 0)
    ) {
      // If the node is a leaf node with data, display elaborations
      setSelectedElaborationData({
        contentDesc: option.name,
        elaborations: option.data, // Now contains objects with 'CdCode' and 'Elaboration'
      });
      setHighlightedElaboration(null); // Reset any highlighted elaboration
    } else if (option.children && option.children.length > 0) {
      // Navigate deeper into the tree
      const newPath = [...categoryPath, option];
      setCategoryPath(newPath);

      // Set navigation direction to forward
      setIsNavigatingForward(true);

      // Reset selected elaborations when navigating forward
      setSelectedElaborationData(null);
      setHighlightedElaboration(null);
    } else {
      // Node has both data and children, or node has no data or children
      console.warn('Selected node is not properly structured.');
    }
  };

  // Handler for breadcrumb navigation
  const handleBreadcrumbClick = (index) => {
    console.log(`Breadcrumb Clicked: Level ${index}`); // Debug
    const newPath = categoryPath.slice(0, index + 1);
    setCategoryPath(newPath);

    // Set navigation direction to backward
    setIsNavigatingForward(false);

    // If navigating back from elaborations, reset selectedElaborationData
    if (selectedElaborationData) {
      setSelectedElaborationData(null);
      setHighlightedElaboration(null);
    }
  };

  // Handler for resetting to Home
  const handleReset = () => {
    console.log('Resetting to Home'); // Debug
    setCategoryPath([]);
    setSelectedElaborationData(null);
    setHighlightedElaboration(null);
    // Set navigation direction to backward
    setIsNavigatingForward(false);
  };

  // Function to get gradient background based on index for consistency
  const getGradientBackground = (index) => {
    return gradientBackgrounds[index % gradientBackgrounds.length];
  };

  // Function to get icon based on category name
  const getCategoryIcon = (name) => {
    return iconMapping[name] || defaultIcon;
  };

  // Handler for clicking an elaboration
  const handleElaborationClick = (elabItem) => {
    setHighlightedElaboration(elabItem);
    console.log('Elaboration Highlighted:', elabItem);
  };

  // Function to retrieve the current path names for display
  const getCurrentPathNames = () => {
    return categoryPath.map((node) => removeDuplicateNames(node.name));
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-11/12 overflow-x-hidden overflow-y-auto bg-white rounded-lg shadow-lg h-5/6">
        <header className="relative flex items-center justify-center p-4 border-b">
          <h2 className="text-2xl font-bold">Slideshow Library</h2>
          <Button onClick={onClose} variant="ghost" className="absolute right-4" aria-label="Close Slideshow App">
            Close
          </Button>
        </header>

        <div className="flex flex-col h-full p-4">
          {/* **Tab Navigation** */}
          <div className="flex mb-4">
            <button
              onClick={() => setActiveTab('Curriculum')}
              className={`mr-2 px-4 py-2 rounded ${
                activeTab === 'Curriculum'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-800'
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              aria-label="Curriculum Tab"
            >
              Curriculum
            </button>
            <button
              onClick={() => setActiveTab('My Lessons')}
              className={`px-4 py-2 rounded ${
                activeTab === 'My Lessons'
                  ? 'bg-blue-600 text-white'
                  : 'bg-gray-200 text-gray-800'
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              aria-label="My Lessons Tab"
            >
              My Lessons
            </button>
          </div>

          {/* **Content Area** */}
          <div className="flex-1 overflow-y-auto">
            {/* **Curriculum Tab Content** */}
            {activeTab === 'Curriculum' && (
              <>
                {/* Search and Filters */}
                <div className="flex flex-col mb-8">
                  {/* Search Bar */}
                  <Input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search folders..."
                    className="mb-6 text-base md:text-lg"
                  />

                  {/* Category Navigation */}
                  <div className="mb-6">
                    {/* Breadcrumb Navigation */}
                    {categoryPath.length > 0 && (
                      <nav className="mb-4" aria-label="Breadcrumb">
                        <ol className="flex items-center space-x-3 text-sm md:text-base">
                          {/* Home Button */}
                          <li>
                            <button
                              onClick={handleReset}
                              className="text-blue-600 underline rounded hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                              aria-label="Go to Home"
                            >
                              Home
                            </button>
                          </li>
                          {getCurrentPathNames().map((crumb, index) => (
                            <li key={index} className="flex items-center">
                              <span className="mx-2 text-gray-400">/</span>
                              <button
                                onClick={() => handleBreadcrumbClick(index)}
                                className="text-blue-600 underline rounded hover:text-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                aria-label={`Go to ${crumb}`}
                              >
                                {crumb}
                              </button>
                            </li>
                          ))}
                        </ol>
                      </nav>
                    )}

                    {/* Search Results Message */}
                    {searchQuery && (
                      <div className="mb-4">
                        <span className="text-gray-600">
                          Filtering results for '{searchQuery}'
                        </span>
                      </div>
                    )}

                    {/* Category Selection Tiles or Elaborations List */}
                    <div>
                      {/* If elaborations are selected, display them with titles */}
                      {selectedElaborationData ? (
                        <div className="p-4 rounded-md shadow-md">
                          {/* Title Section */}
                          <h2 className="mb-2 text-lg font-semibold text-gray-800">
                            {removeDuplicateNames(selectedElaborationData.contentDesc)}
                          </h2>

                          {/* Elaborations List */}
                          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
                            {selectedElaborationData.elaborations.map((elabItem, idx) => (
                              <Button
                                key={idx}
                                onClick={() => handleElaborationClick(elabItem)}
                                className={`flex flex-col items-center justify-center text-center rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 bg-white border border-gray-300 ${
                                  highlightedElaboration === elabItem ? 'ring-2 ring-blue-500' : ''
                                }`}
                                aria-label={`Elaboration ${idx + 1}`}
                              >
                                <span className="mb-2 text-xs font-semibold text-gray-700">
                                  {elabItem.CdCode}
                                </span>
                                <span className="max-w-full px-4 py-2 text-xs font-medium text-black break-words">
                                  {elabItem.Elaboration}
                                </span>
                              </Button>
                            ))}
                          </div>
                        </div>
                      ) : (
                        // Else, display the category selection tiles
                        <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
                          {/* Render available options */}
                          {availableOptions.map((option, index) => (
                            <Card
                              key={option.name || index} // Ensure unique key
                              className={`relative aspect-square flex flex-col justify-center text-center rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105 overflow-hidden cursor-pointer group`}
                              onClick={() => handleSelectOption(option)}
                              aria-label={`Select ${option.name}`}
                            >
                              {/* Top Half with Gradient */}
                              <div
                                className={`flex-1 flex items-center justify-center ${getGradientBackground(
                                  index
                                )}`}
                              >
                                {getCategoryIcon(option.name)}
                              </div>
                              {/* Bottom Half with Reduced Text Size and Border */}
                              <div className="flex items-center justify-center flex-1 p-2 border-t border-gray-300">
                                <span className="text-sm font-semibold text-black">
                                  {removeDuplicateNames(option.name)}
                                </span>
                              </div>

                              {/* Plan Button - Removed since related state and functions are removed */}
                            </Card>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* **My Lessons Tab Content** */}
            {activeTab === 'My Lessons' && (
              <MyLessonsTab
                // Removed handleImportSlideshow and other Firebase-related props
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideshowApp;
