import React, { useState, useEffect } from 'react';
import { db, auth } from '../../../firebase';
import { collection, getDocs, addDoc, serverTimestamp, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { Plus, Trash } from 'lucide-react';
import Modal from '../../common/modal';

// Define a list of 25 preset colours
const PRESET_COLORS = [
  '#EF4444', // Red-500
  '#F97316', // Orange-500
  '#FB923C', // Orange-400
  '#FACC15', // Yellow-400
  '#84CC16', // Lime-500
  '#22C55E', // Emerald-500
  '#10B981', // Emerald-600
  '#06B6D4', // Cyan-500
  '#3B82F6', // Blue-500
  '#6366F1', // Indigo-500
  '#8B5CF6', // Purple-500
  '#A855F7', // Violet-500
  '#D946EF', // Purple-400
  '#EC4899', // Pink-500
  '#F43F5E', // Rose-500
  '#F87171', // Red-400
  '#FBBF24', // Yellow-300
  '#34D399', // Emerald-400
  '#60A5FA', // Blue-400
  '#818CF8', // Indigo-400
  '#A78BFA', // Violet-400
  '#C084FC', // Purple-300
  '#E879F9', // Pink-400
  '#F472B6', // Rose-400
  '#FCA5A5', // Red-300
];

// Define the list of available student names
const AVAILABLE_NAMES = [
  'Buzz', 'Sting', 'Hive', 'Drone', 'Wax', 'Comb', 'Wing', 'Gold', 'Pollen', 'Swarm',
  'Glow', 'Zap', 'Sweet', 'Sun', 'Hum', 'Ray', 'Zip', 'Fly', 'Flash', 'Blink',
  'Fuzz', 'Dart', 'Nest', 'Star', 'Shine', 'Loop', 'Ring', 'Gem', 'Swift', 'Rush',
  'Dawn', 'Burst', 'Flip', 'Blaze', 'Spin', 'Whirl', 'Rise', 'Drift', 'Pulse', 'Dance',
  'Twirl', 'Spark', 'Gleam', 'Snap', 'Wave', 'Fizz', 'Bolt', 'Swish', 'Tilt', 'Sprint',
  'Surge', 'Wink', 'Dash', 'Jolt', 'Chip', 'Glide', 'Chime'
];

// Utility function to randomly select unique names from the AVAILABLE_NAMES list
const getRandomNames = (count) => {
  if (count > AVAILABLE_NAMES.length) {
    throw new Error(`Cannot assign ${count} unique names. Maximum available is ${AVAILABLE_NAMES.length}.`);
  }

  const shuffled = [...AVAILABLE_NAMES].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

const ClassManagement = ({ onClose }) => {
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // State for add class modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newClassName, setNewClassName] = useState('');
  const [numberOfStudents, setNumberOfStudents] = useState(''); // New state for number of students
  const [creatingClass, setCreatingClass] = useState(false);
  const [createError, setCreateError] = useState('');

  // State to track expanded classes
  const [expandedClasses, setExpandedClasses] = useState([]);

  // States for colour selection modal
  const [isColourModalOpen, setIsColourModalOpen] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);

  // States for delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [classToDelete, setClassToDelete] = useState(null);

  // Fetch classes assigned to the current teacher
  const fetchClasses = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setErrorMessage('User not authenticated.');
        setLoading(false);
        return;
      }

      const teacherId = currentUser.uid;
      const classesRef = collection(db, `users/${teacherId}/classes`);
      const querySnapshot = await getDocs(classesRef);
      
      // Assuming classes have a 'createdAt' timestamp for ordering
      const classesData = querySnapshot.docs
        .map((docSnap) => ({
          id: docSnap.id,
          ...docSnap.data(),
        }))
        .sort((a, b) => {
          if (!a.createdAt || !b.createdAt) return 0;
          return a.createdAt.seconds - b.createdAt.seconds;
        }); // Sort by creation time

      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setErrorMessage('Failed to fetch classes.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers to open and close the add class modal
  const openAddModal = () => {
    setNewClassName('');
    setNumberOfStudents('');
    setCreateError('');
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  // Handler to create a new class
  const handleCreateClass = async (e) => {
    e.preventDefault();
    const trimmedClassName = newClassName.trim();
    const studentCount = parseInt(numberOfStudents, 10);

    if (!trimmedClassName) {
      setCreateError('Class name cannot be empty.');
      return;
    }

    if (isNaN(studentCount) || studentCount <= 0) {
      setCreateError('Number of students must be a positive integer.');
      return;
    }

    try {
      // Check if the requested number of students exceeds available names
      if (studentCount > AVAILABLE_NAMES.length) {
        setCreateError(`Cannot assign ${studentCount} unique names. Maximum available is ${AVAILABLE_NAMES.length}.`);
        return;
      }

      setCreatingClass(true);
      setCreateError('');

      const currentUser = auth.currentUser;
      if (!currentUser) {
        setCreateError('User not authenticated.');
        setCreatingClass(false);
        return;
      }

      const teacherId = currentUser.uid;
      const classesRef = collection(db, `users/${teacherId}/classes`);
      
      // Determine the class number by finding the max existing classNumber
      const existingNumbers = classes.map(cls => cls.classNumber);
      const maxNumber = existingNumbers.length > 0 ? Math.max(...existingNumbers) : 0;
      const classNumber = maxNumber + 1;

      // Get random student names
      const studentNames = getRandomNames(studentCount);

      // Add the new class to Firestore
      await addDoc(classesRef, {
        name: trimmedClassName,
        classNumber, // Assign class number
        users: studentNames, // Assign randomly selected student names
        createdAt: serverTimestamp(), // Use server timestamp for consistency
        color: '#3B82F6', // Default colour (Tailwind blue-500)
        // Initialize other fields as necessary
      });

      // Refresh the classes list
      await fetchClasses();

      // Close the add class modal
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error creating class:', error);
      setCreateError(error.message || 'Failed to create class.');
    } finally {
      setCreatingClass(false);
    }
  };

  // Handler to toggle class expansion
  const handleToggle = (classId) => {
    setExpandedClasses((prevExpanded) =>
      prevExpanded.includes(classId)
        ? prevExpanded.filter(id => id !== classId)
        : [...prevExpanded, classId]
    );
  };

  // Handler to initiate colour change
  const handleColourClick = (classId) => {
    setSelectedClassId(classId);
    setIsColourModalOpen(true);
  };

  // Handler for selecting a colour from the grid
  const handleColourSelect = async (newColour) => {
    if (!selectedClassId) return;

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setErrorMessage('User not authenticated.');
        return;
      }

      const teacherId = currentUser.uid;
      const classDocRef = doc(db, `users/${teacherId}/classes`, selectedClassId);

      await updateDoc(classDocRef, {
        color: newColour,
      });

      // Update local state
      setClasses(prevClasses =>
        prevClasses.map(cls =>
          cls.id === selectedClassId ? { ...cls, color: newColour } : cls
        )
      );

      // Reset selected class and close modal
      setSelectedClassId(null);
      setIsColourModalOpen(false);
    } catch (error) {
      console.error('Error updating colour:', error);
      setErrorMessage('Failed to update class colour.');
    }
  };

  // Handler to delete a class
  const handleDeleteClass = async () => {
    if (!classToDelete) return;

    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        setErrorMessage('User not authenticated.');
        setIsDeleteModalOpen(false);
        return;
      }

      const teacherId = currentUser.uid;
      const classDocRef = doc(db, `users/${teacherId}/classes`, classToDelete.id);

      await deleteDoc(classDocRef);

      // Update local state by removing the deleted class
      setClasses((prevClasses) => prevClasses.filter((cls) => cls.id !== classToDelete.id));

      // Close the delete modal
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting class:', error);
      setErrorMessage('Failed to delete class.');
      setIsDeleteModalOpen(false);
    }
  };

  return (
    <div className="relative p-4">
      {/* Close Button */}
      {onClose && (
        <button
          onClick={onClose}
          className="absolute text-gray-500 top-4 right-4 hover:text-gray-700 focus:outline-none"
          aria-label="Close"
        >
          &times;
        </button>
      )}

      {/* Header with Title and Add Button */}
      <div className="grid items-center grid-cols-3 mb-6">
        {/* Left placeholder to balance the grid */}
        <div></div>

        {/* Title */}
        <h1 className="text-3xl font-bold text-center text-gray-800">Class Management</h1>

        {/* Add Class Button with Padding */}
        <div className="flex justify-end pr-20">
          <button
            onClick={openAddModal}
            className="flex items-center justify-center w-10 h-10 text-white bg-blue-500 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none"
            aria-label="Add Class"
          >
            <Plus className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Error Message */}
      {errorMessage && (
        <div className="p-4 mb-6 text-red-700 bg-red-100 border border-red-400 rounded">
          {errorMessage}
        </div>
      )}

      {/* Classes List */}
      <main className="relative">
        {loading ? (
          <div className="text-lg text-center text-gray-600">Loading classes...</div>
        ) : (
          <>
            {classes.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-64">
                <p className="mb-4 text-2xl text-gray-700">You have no classes.</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                {classes.map((cls) => {
                  const isExpanded = expandedClasses.includes(cls.id);
                  return (
                    <div
                      key={cls.id}
                      className={`relative p-4 bg-white rounded-lg shadow cursor-pointer transition-all duration-300 ${
                        isExpanded ? 'h-auto' : 'h-36'
                      }`}
                      onClick={() => handleToggle(cls.id)}
                      role="button"
                      tabIndex="0"
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleToggle(cls.id);
                        }
                      }}
                      aria-expanded={isExpanded}
                      aria-controls={`students-${cls.id}`}
                    >
                      {/* Class Number Badge */}
                      <div className="absolute px-2 py-1 text-xs font-semibold text-white bg-blue-500 rounded top-2 right-2">
                        Class {cls.classNumber}
                      </div>

                      {/* Header with Colour Circle and Class Name */}
                      <div className="flex items-center mb-2">
                        {/* Colour Circle */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the card's onClick
                            handleColourClick(cls.id);
                          }}
                          className="w-3 h-3 mr-1 border border-gray-300 rounded-full focus:outline-none"
                          style={{ backgroundColor: cls.color || '#3B82F6' }}
                          aria-label="Change Class Colour"
                        ></button>

                        {/* Class Name */}
                        <h2 className="text-xl font-semibold text-gray-800">{cls.name}</h2>
                      </div>

                      {/* Student Names - Collapsible */}
                      {isExpanded && (
                        <div className="mt-3">
                          <strong className="text-gray-700">Students:</strong>
                          {cls.users && cls.users.length > 0 ? (
                            <ul className="pl-4 mt-1 text-gray-700 list-disc">
                              {cls.users.map((student, idx) => (
                                <li key={idx} className="text-sm">
                                  {student}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <p className="mt-1 italic text-gray-500">No students yet</p>
                          )}
                        </div>
                      )}

                      {/* Footer with Delete Button */}
                      <div className="flex justify-end mt-4">
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the card's onClick
                            setClassToDelete(cls);
                            setIsDeleteModalOpen(true);
                          }}
                          className="flex items-center justify-center text-gray-500 hover:text-red-600 focus:outline-none"
                          aria-label={`Delete Class ${cls.name}`}
                        >
                          <Trash className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </main>

      {/* Colour Selection Modal */}
      {isColourModalOpen && (
        <Modal onClose={() => setIsColourModalOpen(false)}>
          <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">Select Class Colour</h2>
          <div className="grid grid-cols-5 gap-2">
            {PRESET_COLORS.map((colour, index) => (
              <button
                key={index}
                onClick={() => handleColourSelect(colour)}
                className="w-6 h-6 border border-gray-300 rounded-full focus:outline-none"
                style={{ backgroundColor: colour }}
                aria-label={`Select colour ${colour}`}
              ></button>
            ))}
          </div>
          <div className="flex justify-end mt-6">
            <button
              onClick={() => setIsColourModalOpen(false)}
              className="px-4 py-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none"
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && classToDelete && (
        <Modal onClose={() => setIsDeleteModalOpen(false)}>
          <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">Confirm Deletion</h2>
          <p className="mb-6 text-center text-gray-700">
            Are you sure you want to delete the class <strong>{classToDelete.name}</strong>? This action cannot be undone.
          </p>
          <div className="flex justify-center">
            <button
              onClick={() => setIsDeleteModalOpen(false)}
              className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteClass}
              className="px-4 py-2 text-white bg-red-600 rounded hover:bg-red-700 focus:outline-none"
            >
              Delete
            </button>
          </div>
        </Modal>
      )}

      {/* Modal for Creating a New Class */}
      {isAddModalOpen && (
        <Modal onClose={closeAddModal}>
          <h2 className="mb-4 text-2xl font-semibold text-center text-gray-800">Create New Class</h2>
          <form onSubmit={handleCreateClass}>
            <div className="mb-4">
              <label htmlFor="className" className="block mb-2 text-sm font-medium text-gray-700">
                Class Name
              </label>
              <input
                type="text"
                id="className"
                value={newClassName}
                onChange={(e) => setNewClassName(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter class name"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="numberOfStudents" className="block mb-2 text-sm font-medium text-gray-700">
                Number of Students
              </label>
              <input
                type="number"
                id="numberOfStudents"
                value={numberOfStudents}
                onChange={(e) => setNumberOfStudents(e.target.value)}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter number of students"
                required
                min="1"
                max={AVAILABLE_NAMES.length}
              />
              <p className="mt-1 text-xs text-gray-500">
                Maximum available names: {AVAILABLE_NAMES.length}
              </p>
            </div>
            {createError && (
              <div className="mb-4 text-red-600">
                {createError}
              </div>
            )}
            <div className="flex justify-end">
              <button
                type="button"
                onClick={closeAddModal}
                className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300 focus:outline-none"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={creatingClass}
                className={`px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none ${
                  creatingClass ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {creatingClass ? 'Creating...' : 'Create'}
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default ClassManagement;
