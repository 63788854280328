// src/utils/whiteboarddata.js

import { useRef } from 'react';
import throttle from 'lodash/throttle';
import _ from 'lodash';
import {
  ref as dbRef,
  query,
  orderByChild,
  equalTo,
  get,
  update,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { rtdb, auth } from '../../../firebase';
import { sanitizeData } from './dataSanitizer';

const isDevelopment = process.env.NODE_ENV === 'development';

/**
 * Custom hook to manage whiteboard data updates with change detection and throttling.
 */
export const useWhiteboardData = () => {
  const previousDataRef = useRef({ elements: null, appState: null });

  /**
   * Sends real-time whiteboard data to Firebase RTDB.
   *
   * @param {Object} sanitizedElements - Sanitized Excalidraw elements.
   * @param {Object} sanitizedAppState - Sanitized Excalidraw app state.
   * @returns {Promise<void>}
   */
  const sendUpdate = async (sanitizedElements, sanitizedAppState) => {
    try {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        console.warn('No authenticated user found. Whiteboard data not sent.');
        return;
      }

      // Reference to the 'activeclasses' node
      const activeClassesRef = dbRef(rtdb, 'activeclasses');

      // Query to find classes where 'teacherID' equals currentUser.uid
      const teacherClassesQuery = query(
        activeClassesRef,
        orderByChild('teacherID'),
        equalTo(currentUser.uid)
      );

      const snapshot = await get(teacherClassesQuery);

      if (!snapshot.exists()) {
        console.warn('No active classes found for the current user.');
        return;
      }

      const classes = snapshot.val();
      const updates = {};

      Object.keys(classes).forEach((classId) => {
        // Nesting data under the 'Teacher' node
        updates[`activeclasses/${classId}/whiteboard/Teacher/elements`] = sanitizedElements;
        updates[`activeclasses/${classId}/whiteboard/Teacher/appState`] = sanitizedAppState;
      });

      // Perform a single multi-path update
      await update(dbRef(rtdb), updates);

      if (isDevelopment) {
        console.log('Real-time whiteboard data successfully sent to all active classes under Teacher node.');
      }
    } catch (error) {
      console.error('Error sending real-time whiteboard data:', error);
      throw error; // Propagate the error to the caller if needed
    }
  };

  /**
   * Throttled version of sendUpdate to limit update frequency.
   */
  const throttledSendUpdate = throttle(sendUpdate, 1000); // 1 second throttle

  /**
   * Sends real-time whiteboard data to Firebase RTDB only if there are changes.
   *
   * @param {Object} data - The whiteboard data to send.
   * @param {Array} data.elements - The Excalidraw elements.
   * @param {Object} data.appState - The current app state of Excalidraw.
   * @returns {Promise<void>}
   */
  const updateWhiteboardData = async (data) => {
    const { elements, appState } = data;

    // Compare with previous state
    const elementsChanged = !_.isEqual(elements, previousDataRef.current.elements);
    const appStateChanged = !_.isEqual(appState, previousDataRef.current.appState);

    if (!elementsChanged && !appStateChanged) {
      // No changes detected; skip the update
      return;
    }

    // Update the previous state refs
    previousDataRef.current = { elements: _.cloneDeep(elements), appState: _.cloneDeep(appState) };

    // Sanitize the data to remove undefined values
    const sanitizedElements = sanitizeData(elements);
    const sanitizedAppState = sanitizeData(appState);

    // Optional: Log sanitized data for debugging
    if (isDevelopment) {
      console.log('Sanitized Elements:', sanitizedElements);
      console.log('Sanitized AppState:', sanitizedAppState);
    }

    // Throttled send update
    throttledSendUpdate(sanitizedElements, sanitizedAppState);
  };

  /**
   * Sends whiteboard blob data to all active classes of the current teacher.
   * Utilizes Firebase Storage for efficient binary uploads.
   *
   * @param {Object} data - The whiteboard data to send.
   * @param {Array} data.elements - The Excalidraw elements.
   * @param {Blob} data.blob - The image blob of the whiteboard.
   * @returns {Promise<void>}
   */
  const sendWhiteboardData = async (data) => {
    const { elements, blob } = data;

    // Sanitize the elements to remove undefined values
    const sanitizedElements = sanitizeData(elements);

    if (isDevelopment) {
      console.log('Sanitized Elements for Blob:', sanitizedElements);
    }

    try {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        console.warn('No authenticated user found. Whiteboard data not sent.');
        return;
      }

      // Reference to the 'activeclasses' node
      const activeClassesRef = dbRef(rtdb, 'activeclasses');

      // Query to find classes where 'teacherID' equals currentUser.uid
      const teacherClassesQuery = query(
        activeClassesRef,
        orderByChild('teacherID'),
        equalTo(currentUser.uid)
      );

      const snapshot = await get(teacherClassesQuery);

      if (!snapshot.exists()) {
        console.warn('No active classes found for the current user.');
        return;
      }

      const classes = snapshot.val();
      const storage = getStorage();
      const timestamp = Date.now();

      // Define a unique path for the blob in Firebase Storage
      const blobStoragePath = `whiteboards/${currentUser.uid}/${timestamp}.png`;
      const blobStorageRef = storageRef(storage, blobStoragePath);

      // Upload the blob to Firebase Storage
      await uploadBytes(blobStorageRef, blob);
      const downloadURL = await getDownloadURL(blobStorageRef);

      const updates = {};

      Object.keys(classes).forEach((classId) => {
        // Nesting blob data under the 'Teacher' node
        updates[`activeclasses/${classId}/whiteboard/Teacher/blob`] = {
          url: downloadURL,
          updatedAt: new Date().toISOString(),
        };
      });

      // Perform a single multi-path update to RTDB with the blob URLs
      await update(dbRef(rtdb), updates);

      if (isDevelopment) {
        console.log('Whiteboard blob data successfully sent to all active classes under Teacher node.');
      }
    } catch (error) {
      console.error('Error sending whiteboard blob data:', error);
      throw error; // Propagate the error to the caller if needed
    }
  };

  /**
   * Throttled version of sendWhiteboardData to limit update frequency.
   */
  const throttledSendWhiteboardData = throttle(sendWhiteboardData, 1000); // 1 second throttle

  return {
    updateWhiteboardData,
    sendWhiteboardData: throttledSendWhiteboardData,
  };
};
