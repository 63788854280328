// src/components/Students/apps/StudentTests.js

import React from 'react';

const StudentTests = () => {
  return (
    <div className="student-tests">
      <h2>Tests</h2>
      <p>This is where the tests content will go.</p>
    </div>
  );
};

export default StudentTests;

