// src/contexts/AuthContext.js

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  onAuthStateChanged,
  setPersistence,
  browserLocalPersistence,
  signOut,
} from 'firebase/auth';
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthContext = createContext();

const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 60 minutes

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  const inactivityTimeoutRef = useRef(null);
  const unsubscribeRef = useRef(null);

  /**
   * Handles user sign-out due to inactivity.
   */
  const handleSignOut = useCallback(() => {
    toast.info('You have been signed out due to inactivity.');

    signOut(auth)
      .then(() => {
        console.log('User signed out due to inactivity');
      })
      .catch((error) => {
        console.error('Error signing out:', error);
        toast.error('Error signing out due to inactivity.');
      });
  }, []);

  /**
   * Resets the inactivity timeout whenever user interacts.
   */
  const resetInactivityTimeout = useCallback(() => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(handleSignOut, INACTIVITY_TIMEOUT);
  }, [handleSignOut]);

  /**
   * Initializes event listeners for user activity to track inactivity.
   */
  const initializeActivityListeners = useCallback(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'touchstart'];

    const eventHandler = () => {
      resetInactivityTimeout();
    };

    events.forEach((event) => {
      window.addEventListener(event, eventHandler);
    });

    resetInactivityTimeout();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, eventHandler);
      });
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [resetInactivityTimeout]);

  /**
   * Updates the user's online status in Firestore.
   * @param {FirebaseFirestore.DocumentReference} userDocRef - Reference to the user's Firestore document.
   */
  const updateUserOnline = useCallback(async (userDocRef) => {
    try {
      await setDoc(
        userDocRef,
        {
          isOnline: true,
          lastActive: serverTimestamp(),
        },
        { merge: true }
      );
      console.log('User online status updated.');
    } catch (error) {
      console.error('Error updating user online status:', error);
    }
  }, []);

  /**
   * Initializes authentication state listener.
   */
  useEffect(() => {
    const initAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        console.log('Auth persistence set to local');

        unsubscribeRef.current = onAuthStateChanged(auth, async (user) => {
          if (user) {
            console.log('User detected:', user.uid);
            setCurrentUser(user);

            if (!user.isAnonymous) {
              try {
                const userDocRef = doc(db, 'users', user.uid);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                  const userData = userDoc.data();
                  const role = userData.role || null;
                  setUserRole(role);
                  console.log(`User role for ${user.uid}: ${role}`);

                  if (role === 'teacher') {
                    await updateUserOnline(userDocRef);
                  }
                } else {
                  console.error('User document does not exist for:', user.uid);
                  setUserRole(null);
                }
              } catch (error) {
                console.error('Error retrieving user role:', error);
                setUserRole(null);
              }
            } else {
              // Handle anonymous user
              console.log('Anonymous user detected:', user.uid);
              setUserRole('student'); // Set role to 'student' for anonymous users
              setLoading(false); // Since there's no need to fetch user data
            }
          } else {
            console.log('No user is signed in');
            setCurrentUser(null);
            setUserRole(null);
          }
          setLoading(false);
        });
      } catch (error) {
        console.error('Error setting auth persistence:', error);
        setLoading(false);
      }
    };

    initAuth();

    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, [updateUserOnline]); // Removed currentUser from dependencies

  /**
   * Initializes activity listeners when a user is authenticated.
   */
  useEffect(() => {
    if (currentUser) {
      const cleanup = initializeActivityListeners();
      return cleanup;
    }
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
  }, [currentUser, initializeActivityListeners]);

  const value = useMemo(
    () => ({
      currentUser,
      userRole,
      loading,
    }),
    [currentUser, userRole, loading]
  );

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
