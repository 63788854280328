// src/components/common/Modal.js

import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';

const Modal = ({ children, onClose, disableClose }) => {
  /**
   * Handle click events on the overlay.
   * If disableClose is true, prevent closing the modal.
   */
  const handleOverlayClick = (e) => {
    if (!disableClose && e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={handleOverlayClick}
    >
      <div className="relative w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
        {/* Close Button */}
        {!disableClose && (
          <button
            onClick={onClose}
            className="absolute text-gray-500 top-2 right-2 hover:text-gray-700 focus:outline-none"
            aria-label="Close"
          >
            <X size={24} />
          </button>
        )}
        {/* Modal Content */}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  disableClose: PropTypes.bool,
};

Modal.defaultProps = {
  disableClose: false,
};

export default Modal;
