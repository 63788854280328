// src/components/Students/StudentHomepage.js

import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/beetitle.svg';
import { Hexagons } from './Homepage/HexagonButton';
import './Homepage/HexagonButton.css';
import Bee from './Homepage/Bee copy';
import treeImage from './Homepage/gif1.gif';
import '../../styles/StudentHomepage.css';

// Import Student apps and Modal
import StudentWhiteboard from './apps/studentwhiteboard';
import StudentSlides from './apps/StudentSlides';
import StudentYourHive from './apps/StudentYourHive';
import StudentSites from './apps/StudentSites';
import StudentTests from './apps/StudentTests';
import StudentJournal from './apps/StudentJournal';
import StudentGames from './apps/StudentGames';
import Modal from '../common/modal'; // Ensure correct import path

import { useStudentManager } from '../../contexts/Studentmanager';
import { rtdb } from '../../firebase';
import { ref, onValue, off } from 'firebase/database';

const StudentHomepage = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { studentInfo } = useStudentManager();
  const [moduleStatuses, setModuleStatuses] = useState({});

  const [loading, setLoading] = useState(true);
  const [studentData, setStudentData] = useState(null);

  const hasLoggedOutRef = useRef(false);

  const treeContainerRef = useRef(null);
  const [treeDimensions, setTreeDimensions] = useState(null);

  // State to manage modal visibility for each app
  const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false);
  const [isSlidesOpen, setIsSlidesOpen] = useState(false);
  const [isYourHiveOpen, setIsYourHiveOpen] = useState(false);
  const [isSitesOpen, setIsSitesOpen] = useState(false);
  const [isTestsOpen, setIsTestsOpen] = useState(false);
  const [isJournalOpen, setIsJournalOpen] = useState(false);
  const [isGamesOpen, setIsGamesOpen] = useState(false);

  // Ref to keep track of previous moduleStatuses
  const prevModuleStatusesRef = useRef({});

  // State to store active bees
  const [activeBees, setActiveBees] = useState([]);

  // Fetch module statuses from RTDB
  useEffect(() => {
    if (!studentInfo || !studentInfo.classId) return;

    const moduleStatusRef = ref(
      rtdb,
      `activeclasses/${studentInfo.classId}/modulestatus`
    );

    const unsubscribe = onValue(moduleStatusRef, (snapshot) => {
      if (snapshot.exists()) {
        setModuleStatuses(snapshot.val());
      }
    });

    return () => {
      off(moduleStatusRef, 'value', unsubscribe);
    };
  }, [studentInfo]);

  // Fetch active bees from RTDB
  useEffect(() => {
    if (!studentInfo || !studentInfo.classId) return;

    const beeNamesRef = ref(
      rtdb,
      `activeclasses/${studentInfo.classId}/beeNames`
    );

    const unsubscribe = onValue(beeNamesRef, (snapshot) => {
      if (snapshot.exists()) {
        const beeData = snapshot.val();
        const activeBeeNames = Object.keys(beeData).filter(
          (beeName) => beeData[beeName].logged === 'yes'
        );
        setActiveBees(activeBeeNames);
      } else {
        setActiveBees([]);
      }
    });

    return () => {
      off(beeNamesRef, 'value', unsubscribe);
    };
  }, [studentInfo]);

  // Effect to handle locked modules and ensure only one modal is open
  useEffect(() => {
    if (!moduleStatuses) return;

    const prevModuleStatuses = prevModuleStatusesRef.current;

    // Identify modules that have changed to 'locked'
    const newlyLockedModules = Object.keys(moduleStatuses).filter(
      (key) =>
        moduleStatuses[key] === 'locked' &&
        prevModuleStatuses[key] !== 'locked'
    );

    if (newlyLockedModules.length > 0) {
      // Close all modals first
      setIsWhiteboardOpen(false);
      setIsSlidesOpen(false);
      setIsYourHiveOpen(false);
      setIsSitesOpen(false);
      setIsTestsOpen(false);
      setIsJournalOpen(false);
      setIsGamesOpen(false);

      // Open the new locked modals
      newlyLockedModules.forEach((moduleKey) => {
        switch (moduleKey) {
          case 'whiteboardactive':
            setIsWhiteboardOpen(true);
            break;
          case 'slidesactive':
            setIsSlidesOpen(true);
            break;
          case 'characteractive':
            setIsYourHiveOpen(true);
            break;
          case 'sitesactive':
            setIsSitesOpen(true);
            break;
          case 'testsactive':
            setIsTestsOpen(true);
            break;
          case 'journalactive':
            setIsJournalOpen(true);
            break;
          case 'gamesactive':
            setIsGamesOpen(true);
            break;
          default:
            break;
        }
      });
    }

    // Update the previous moduleStatuses ref
    prevModuleStatusesRef.current = moduleStatuses;
  }, [moduleStatuses]);

  // Handlers to open modals
  const handleOpenWhiteboard = useCallback(() => {
    setIsWhiteboardOpen(true);
  }, []);

  const handleOpenSlides = useCallback(() => {
    setIsSlidesOpen(true);
  }, []);

  const handleOpenYourHive = useCallback(() => {
    setIsYourHiveOpen(true);
  }, []);

  const handleOpenSites = useCallback(() => {
    setIsSitesOpen(true);
  }, []);

  const handleOpenTests = useCallback(() => {
    setIsTestsOpen(true);
  }, []);

  const handleOpenJournal = useCallback(() => {
    setIsJournalOpen(true);
  }, []);

  const handleOpenGames = useCallback(() => {
    setIsGamesOpen(true);
  }, []);

  // Handlers to close modals
  const handleCloseWhiteboard = useCallback(() => {
    if (moduleStatuses.whiteboardactive === 'locked') {
      // Do nothing if locked
      return;
    }
    setIsWhiteboardOpen(false);
  }, [moduleStatuses]);

  const handleCloseSlides = useCallback(() => {
    if (moduleStatuses.slidesactive === 'locked') {
      return;
    }
    setIsSlidesOpen(false);
  }, [moduleStatuses]);

  const handleCloseYourHive = useCallback(() => {
    if (moduleStatuses.characteractive === 'locked') {
      return;
    }
    setIsYourHiveOpen(false);
  }, [moduleStatuses]);

  const handleCloseSites = useCallback(() => {
    if (moduleStatuses.sitesactive === 'locked') {
      return;
    }
    setIsSitesOpen(false);
  }, [moduleStatuses]);

  const handleCloseTests = useCallback(() => {
    if (moduleStatuses.testsactive === 'locked') {
      return;
    }
    setIsTestsOpen(false);
  }, [moduleStatuses]);

  const handleCloseJournal = useCallback(() => {
    if (moduleStatuses.journalactive === 'locked') {
      return;
    }
    setIsJournalOpen(false);
  }, [moduleStatuses]);

  const handleCloseGames = useCallback(() => {
    if (moduleStatuses.gamesactive === 'locked') {
      return;
    }
    setIsGamesOpen(false);
  }, [moduleStatuses]);

  // Modify the handlers to be null if the module is inactive
  const handleStartWhiteboard =
    moduleStatuses.whiteboardactive === 'active' ||
    moduleStatuses.whiteboardactive === 'locked'
      ? handleOpenWhiteboard
      : null;

  const handleStartSlides =
    moduleStatuses.slidesactive === 'active' ||
    moduleStatuses.slidesactive === 'locked'
      ? handleOpenSlides
      : null;

  const handleStartYourHive =
    moduleStatuses.characteractive === 'active' ||
    moduleStatuses.characteractive === 'locked'
      ? handleOpenYourHive
      : null;

  const handleStartSites =
    moduleStatuses.sitesactive === 'active' ||
    moduleStatuses.sitesactive === 'locked'
      ? handleOpenSites
      : null;

  const handleStartTests =
    moduleStatuses.testsactive === 'active' ||
    moduleStatuses.testsactive === 'locked'
      ? handleOpenTests
      : null;

  const handleStartJournal =
    moduleStatuses.journalactive === 'active' ||
    moduleStatuses.journalactive === 'locked'
      ? handleOpenJournal
      : null;

  const handleStartGames =
    moduleStatuses.gamesactive === 'active' ||
    moduleStatuses.gamesactive === 'locked'
      ? handleOpenGames
      : null;

  /**
   * Handles user logout.
   */
  const handleLogout = useCallback(async () => {
    if (hasLoggedOutRef.current) return;
    hasLoggedOutRef.current = true;

    console.log('handleLogout triggered.');

    if (!currentUser) {
      console.log('No authenticated user. Redirecting to home.');
      navigate('/');
      return;
    }

    // Add your logout logic here if needed

    navigate('/');
  }, [currentUser, navigate]);

  /**
   * Fetches student data and updates the loading state.
   */
  useEffect(() => {
    const fetchStudentData = async () => {
      if (!currentUser) {
        setLoading(false);
        navigate('/');
        return;
      }

      // Simulate fetching data
      setTimeout(() => {
        setStudentData({ name: 'Student Name' });
        setLoading(false);
      }, 1000);
    };

    fetchStudentData();
  }, [currentUser, navigate]);

  /**
   * Updates tree dimensions based on the tree container's bounding rectangle.
   */
  const updateTreeDimensions = useCallback(() => {
    if (treeContainerRef.current) {
      const rect = treeContainerRef.current.getBoundingClientRect();
      setTreeDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  // Update tree dimensions on window resize
  useEffect(() => {
    updateTreeDimensions(); // Initial dimension calculation
    window.addEventListener('resize', updateTreeDimensions);

    return () => {
      window.removeEventListener('resize', updateTreeDimensions);
    };
  }, [updateTreeDimensions]);

  // Extract active bees with logged: 'yes' whenever activeClasses change
  // (Already handled in the beeNames listener above)

  /**
   * Renders a loading screen while data is being fetched.
   */
  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-50 to-green-50">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  /**
   * Renders the main homepage UI.
   */
  return (
    <div className="relative max-w-full min-h-screen p-0 bg-gradient-to-br from-blue-50 to-green-50">
      {/* Header with Logo */}
      <header className="flex items-center justify-center mb-6">
        <img src={logo} alt="Logo" className="w-auto h-40" />
      </header>

      {/* Tree Image and Bees Container */}
      <div className="relative max-w-2xl mx-auto mb-20 bg-transparent p-30">
        <div className="relative inline-block" ref={treeContainerRef}>
          <img
            src={treeImage}
            alt="Welcome Tree"
            className="mx-auto"
            onLoad={updateTreeDimensions} // Trigger when image loads
          />

          {/* Bees Flying Around the Tree */}
          {treeDimensions && (
            <div className="absolute inset-0">
              {/* Render active bees */}
              {activeBees.map((beeName, index) => (
                <Bee
                  key={index}
                  name={beeName}
                  boundaries={treeDimensions}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Tiles Section with Hexagon Arrangement */}
      <Hexagons
        handleStartWhiteboard={handleStartWhiteboard}
        handleStartSlides={handleStartSlides}
        handleStartYourHive={handleStartYourHive}
        handleStartSites={handleStartSites}
        handleStartTests={handleStartTests}
        handleStartJournal={handleStartJournal}
        handleStartGames={handleStartGames}
        moduleStatuses={moduleStatuses}
      />

      {/* Footer */}
      <footer className="fixed left-0 w-full text-center text-gray-600 bottom-2">
        <p>&copy; 2024 Learning Resources. All rights reserved.</p>
      </footer>

      {/* Modals for each app */}
      {isWhiteboardOpen && (
        <Modal
          onClose={handleCloseWhiteboard}
          disableClose={moduleStatuses.whiteboardactive === 'locked'}
        >
          <StudentWhiteboard />
        </Modal>
      )}

      {isSlidesOpen && (
        <Modal
          onClose={handleCloseSlides}
          disableClose={moduleStatuses.slidesactive === 'locked'}
        >
          <StudentSlides />
        </Modal>
      )}

      {isYourHiveOpen && (
        <Modal
          onClose={handleCloseYourHive}
          disableClose={moduleStatuses.characteractive === 'locked'}
        >
          <StudentYourHive />
        </Modal>
      )}

      {isSitesOpen && (
        <Modal
          onClose={handleCloseSites}
          disableClose={moduleStatuses.sitesactive === 'locked'}
        >
          <StudentSites />
        </Modal>
      )}

      {isTestsOpen && (
        <Modal
          onClose={handleCloseTests}
          disableClose={moduleStatuses.testsactive === 'locked'}
        >
          <StudentTests />
        </Modal>
      )}

      {isJournalOpen && (
        <Modal
          onClose={handleCloseJournal}
          disableClose={moduleStatuses.journalactive === 'locked'}
        >
          <StudentJournal />
        </Modal>
      )}

      {isGamesOpen && (
        <Modal
          onClose={handleCloseGames}
          disableClose={moduleStatuses.gamesactive === 'locked'}
        >
          <StudentGames />
        </Modal>
      )}
    </div>
  );
};

export default React.memo(StudentHomepage);
