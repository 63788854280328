// src/components/Teachers/TeacherHomepage.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { db } from '../../../firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  onSnapshot,
} from 'firebase/firestore';
import TeacherExcalidrawWhiteboard from '../Whiteboard/TeacherExcalidrawWhiteboard';
import TeacherWorkbook from '../Workbook/teacherworkbook';
import SlideshowApp from '../Slideshow/SlideshowApp';
import AssessmentApp from '../AssessmentApp';
import ClassManagement from '../Management/classmanagement';
import Sidebar from '../Management/Sidebar';
import Toolbar from './toolbar';
import { useDataController } from '../../../contexts/datacontroller'; // Import useDataController
import logo from '../../assets/beetitle.svg';
import { ChevronRight, ChevronLeft } from 'lucide-react';
import { Hexagons } from './HexagonButton'; // Import Hexagons component
import './HexagonButton.css';
import Bee from './Bee copy'; // Import the updated Bee component
import './TeacherHomepage.css';
import ViewSites from '../Sites/viewsites';
import Games from '../games/games';
import Tests from '../Tests/Tests';
import treeImage from './gif1.gif';

const TeacherHomepage = () => {
  const { currentUser, userRole } = useAuth();
  console.log(
    'TeacherHomepage rendered. CurrentUser:',
    currentUser,
    'UserRole:',
    userRole
  );

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Initialize toolbar visibility from localStorage or default to false
  const [isToolbarVisible, setIsToolbarVisible] = useState(() => {
    const savedState = localStorage.getItem('isToolbarVisible');
    return savedState ? JSON.parse(savedState) : false; // Default to false
  });

  const [username, setUsername] = useState('');
  const [fullscreenApp, setFullscreenApp] = useState(null);
  const [classes, setClasses] = useState([]);
  const [showClassManagement, setShowClassManagement] = useState(false);

  // Ref to the tree container
  const treeContainerRef = useRef(null);
  const [treeDimensions, setTreeDimensions] = useState(null);

  // Import activeClasses from DataControllerContext
  const { activeClasses, isLoading } = useDataController();

  // State variable to manage the visibility of each class code
  const [shownClassCodes, setShownClassCodes] = useState({});

  // State to store active bees
  const [activeBees, setActiveBees] = useState([]);

  // Function to toggle the visibility of a specific class code
  const toggleClassCode = (classId) => {
    setShownClassCodes((prev) => ({
      ...prev,
      [classId]: !prev[classId],
    }));
  };

  // Define updateTreeDimensions outside the useEffect
  const updateTreeDimensions = useCallback(() => {
    if (treeContainerRef.current) {
      const rect = treeContainerRef.current.getBoundingClientRect();
      setTreeDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
  }, []);

  // Update the useEffect to use updateTreeDimensions
  useEffect(() => {
    updateTreeDimensions(); // Initial dimension calculation
    window.addEventListener('resize', updateTreeDimensions);

    return () => {
      window.removeEventListener('resize', updateTreeDimensions);
    };
  }, [updateTreeDimensions]);

  useEffect(() => {
    console.log('TeacherHomepage useEffect triggered.');
    let unsubscribeClasses = null;

    const fetchData = async () => {
      if (currentUser) {
        console.log('Fetching data for currentUser:', currentUser.uid);
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          console.log('Fetching user document:', userDocRef.path);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            console.log('User data fetched:', userData);
            setUsername(userData.name || 'Teacher');
          } else {
            console.warn('No user document found for:', currentUser.uid);
            setUsername('Teacher');
          }

          const classesQuery = query(
            collection(db, `users/${currentUser.uid}/classes`)
          );
          console.log('Setting up onSnapshot listener for classes.');
          unsubscribeClasses = onSnapshot(classesQuery, (snapshot) => {
            const fetchedClasses = snapshot.docs.map((docSnap) => ({
              id: docSnap.id,
              ...docSnap.data(),
            }));
            console.log('Fetched classes:', fetchedClasses);
            setClasses(fetchedClasses);
          });
        } catch (error) {
          console.error('Error fetching data:', error);
          setUsername('Teacher');
        }
      } else {
        console.log('No currentUser. Clearing classes and username.');
        setClasses([]);
        setUsername('');
      }
    };

    fetchData();

    return () => {
      if (unsubscribeClasses) {
        console.log('Unsubscribing from classes onSnapshot.');
        unsubscribeClasses();
      }
    };
  }, [currentUser?.uid]);

  // Persist toolbar visibility state to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('isToolbarVisible', JSON.stringify(isToolbarVisible));
  }, [isToolbarVisible]);

  const handleStartApp = useCallback((appName, payload = {}) => {
    console.log(`handleStartApp called with appName: ${appName}, payload:`, payload);
    setFullscreenApp({ appName, ...payload });
  }, []);

  const handleCloseApp = useCallback(() => {
    console.log('handleCloseApp called.');
    setFullscreenApp(null);
  }, []);

  const toggleSidebar = useCallback(() => {
    console.log(`Sidebar toggle. Currently open: ${isSidebarOpen}`);
    setIsSidebarOpen((prev) => !prev);
  }, [isSidebarOpen]);

  const handleManageClasses = useCallback(() => {
    console.log('handleManageClasses called.');
    setShowClassManagement(true);
  }, []);

  const handleCloseClassManagement = useCallback(() => {
    console.log('handleCloseClassManagement called.');
    setShowClassManagement(false);
  }, []);

  // Handler Functions for Hexagons
  const handleStartSlides = useCallback(() => {
    console.log('handleStartSlides called.');
    if (classes.length === 0) {
      alert('No classes available to start Slides.');
      return;
    }
    const classID = classes[0].id; // Modify as needed
    handleStartApp('Slideshow', { classID });
  }, [classes, handleStartApp]);

  const handleStartWhiteboard = useCallback(() => {
    console.log('handleStartWhiteboard called.');
    if (classes.length === 0) {
      alert('No classes available to start the Whiteboard.');
      return;
    }
    const activeClassIDs = classes.map((cls) => cls.id);
    handleStartApp('Whiteboard', { activeClassIDs });
  }, [classes, handleStartApp]);

  const handleStartBookQuest = useCallback(() => {
    console.log('handleStartBookQuest called.');
    if (classes.length === 0) {
      alert('No classes available to start Book Quest.');
      return;
    }
    const classID = classes[0].id; // Modify as needed
    handleStartApp('BookQuest', { classID });
  }, [classes, handleStartApp]);

  const handleStartAssessment = useCallback(() => {
    console.log('handleStartAssessment called.');
    if (classes.length === 0) {
      alert('No classes available to start Assessment.');
      return;
    }
    const classID = classes[0].id; // Modify as needed
    handleStartApp('Assessment', { classID });
  }, [classes, handleStartApp]);

  const handleStartTeacherWorkbook = useCallback(() => {
    console.log('handleStartTeacherWorkbook called.');
    if (classes.length === 0) {
      alert('No classes available to open the Workbook.');
      return;
    }
    const classID = classes[0].id; // Modify as needed
    handleStartApp('Workbook', { classID });
  }, [classes, handleStartApp]);

  const handleStartViewSites = useCallback(() => {
    console.log('handleStartViewSites called.');
    handleStartApp('ViewSites');
  }, [handleStartApp]);

  const handleStartGames = useCallback(() => {
    console.log('handleStartGames called.');
    if (classes.length === 0) {
      alert('No classes available to open Games.');
      return;
    }
    const classID = classes[0].id; // Modify as needed
    handleStartApp('Games', { classID });
  }, [classes, handleStartApp]);

  const handleStartTests = useCallback(() => {
    console.log('handleStartTests called.');
    if (classes.length === 0) {
      alert('No classes available to open Tests.');
      return;
    }
    const classID = classes[0].id; // Modify as needed
    handleStartApp('Tests', { classID });
  }, [classes, handleStartApp]);

  // Extract active bees with logged: 'yes' whenever activeClasses change
  useEffect(() => {
    const bees = [];
    for (const classId in activeClasses) {
      const classData = activeClasses[classId];
      if (classData.beeNames) {
        for (const beeName in classData.beeNames) {
          if (classData.beeNames[beeName].logged === 'yes') {
            bees.push(beeName);
          }
        }
      }
    }
    setActiveBees(bees);
  }, [activeClasses]);

  return (
    <div className="relative max-w-full min-h-screen p-0 bg-gradient-to-br from-blue-50 to-green-50">
      {/* Sidebar and Toggle Button */}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        username={username}
        handleStartApp={handleStartApp}
        onManageClasses={handleManageClasses}
        classes={classes}
        setClasses={setClasses}
      />
      <button
        onClick={toggleSidebar}
        className={`fixed left-0 top-1/2 transform -translate-y-1/2 bg-white text-blue-500 p-1 rounded-r-lg shadow-lg transition-all duration-300 ${
          isSidebarOpen ? 'translate-x-64' : 'translate-x-0'
        }`}
        style={{ height: '100px', zIndex: 40 }}
      >
        {isSidebarOpen ? <ChevronLeft size={40} /> : <ChevronRight size={40} />}
      </button>

      {/* Header with Logo */}
      <header className="flex items-center justify-center mb-6">
        <img src={logo} alt="Logo" className="w-auto h-40" />
      </header>

      {/* Class Codes Display */}
      <div className="px-4 mb-6 text-center">
        {isLoading ? (
          <p className="text-gray-500">Loading...</p>
        ) : Object.keys(activeClasses).length === 0 ? (
          <p className="text-gray-500">No active classes</p>
        ) : (
          <div className="flex flex-row flex-wrap justify-center gap-4">
            {Object.values(activeClasses).map((classData) => (
              <button
                key={classData.id}
                onClick={() => toggleClassCode(classData.id)}
                className={`px-4 py-2 rounded-md font-bold shadow-md transition-colors duration-300 focus:outline-none ${
                  shownClassCodes[classData.id]
                    ? 'bg-orange-500 text-white hover:bg-orange-600'
                    : 'bg-gray-500 text-white hover:bg-gray-600'
                }`}
              >
                {shownClassCodes[classData.id]
                  ? `${classData.name}: ${classData.classcode}`
                  : `${classData.name}`}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Tree Image and Bees Container */}
      <div className="relative max-w-2xl mx-auto mb-20 bg-transparent p-30">
        <div className="relative inline-block" ref={treeContainerRef}>
          <img
            src={treeImage}
            alt="Welcome Tree"
            className="mx-auto"
            onLoad={updateTreeDimensions} // Trigger when image loads
          />

          {/* Bees Flying Around the Tree */}
          {treeDimensions && (
            <div className="absolute inset-0">
              {/* Render active bees */}
              {activeBees.map((beeName, index) => (
                <Bee
                  key={index}
                  name={beeName}
                  boundaries={treeDimensions}
                />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Tiles Section with Hexagon Arrangement */}
      <Hexagons
        handleStartSlides={handleStartSlides}
        handleStartWhiteboard={handleStartWhiteboard}
        handleStartBookQuest={handleStartBookQuest}
        handleStartAssessment={handleStartAssessment}
        handleStartTeacherWorkbook={handleStartTeacherWorkbook}
        handleStartViewSites={handleStartViewSites}
        handleStartGames={handleStartGames}
        handleStartTests={handleStartTests}
      />

      {/* Footer */}
      <footer className="fixed left-0 w-full text-center text-gray-600 bottom-2">
        <p>&copy; 2024 Quick Thinkers. All rights reserved.</p>
      </footer>

      {/* Toolbar Component */}
      <Toolbar
        isVisible={isToolbarVisible}
        toggleToolbar={() => setIsToolbarVisible((prev) => !prev)}
        handleShowClassCodes={() => {}}
        handleStartApp={handleStartApp}
      />

      {/* Fullscreen App Overlays */}
      {fullscreenApp && fullscreenApp.appName && (
        <>
          {fullscreenApp.appName === 'Whiteboard' && (
            <TeacherExcalidrawWhiteboard
              show={true}
              handleClose={handleCloseApp}
              activeClassIDs={fullscreenApp.activeClassIDs}
            />
          )}
          {fullscreenApp.appName === 'Workbook' && (
            <TeacherWorkbook
              onClose={handleCloseApp}
              classID={fullscreenApp.classID}
            />
          )}
          {fullscreenApp.appName === 'Slideshow' && (
            <SlideshowApp
              onClose={handleCloseApp}
              classID={fullscreenApp.classID}
            />
          )}
          {fullscreenApp.appName === 'Assessment' && (
            <AssessmentApp
              onClose={handleCloseApp}
              classID={fullscreenApp.classID}
            />
          )}
          {fullscreenApp.appName === 'ClassManagement' && (
            <ClassManagement
              onClose={handleCloseClassManagement}
              classes={classes}
              setClasses={setClasses}
            />
          )}
          {fullscreenApp.appName === 'ViewSites' && (
            <ViewSites show={true} handleClose={handleCloseApp} />
          )}
          {fullscreenApp.appName === 'Games' && (
            <Games onClose={handleCloseApp} classID={fullscreenApp.classID} />
          )}
          {fullscreenApp.appName === 'Tests' && (
            <Tests onClose={handleCloseApp} classID={fullscreenApp.classID} />
          )}
          {/* Add other apps if necessary */}
        </>
      )}

      {/* Class Management Modal */}
      {showClassManagement && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-11/12 max-w-3xl p-0 bg-white rounded-lg shadow-lg">
            <ClassManagement
              onClose={handleCloseClassManagement}
              classes={classes}
              setClasses={setClasses}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TeacherHomepage;
